import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'app-simple-table-search-bar',
  templateUrl: './simple-table-search-bar.component.html',
  styleUrls: ['./simple-table-search-bar.component.scss'],
})
export class SimpleTableSearchBarComponent implements OnInit {
  @Input() options: Set<string> = new Set<string>();
  @Output() onSearch = new EventEmitter<string>();

  filteredOptions: Observable<string[]> = new Observable<string[]>();
  formControl: FormControl = new FormControl('');

  constructor() {}

  ngOnInit() {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    this.filteredOptions.subscribe(() => {
      this.onSearch.emit(this.formControl.value);
    });
  }

  public update(): void {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toString().toLowerCase();

    return [...this.options].filter((option) =>
      option.toString().toLowerCase().includes(filterValue)
    );
  }
}
