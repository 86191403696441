<section>
  <mat-card
    [ngClass]="{
      hidden: !isFilterdataLoaded()
    }"
  >
    <mat-card-header>
      <mat-card-title> Filter Documents </mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    <div class="filter-group">
      <app-analysis-type-filter
        class="filter-input"
        [selection]="filter.analysis"
        (selectionChange)="onAnalysisSelectionChange($event)"
      ></app-analysis-type-filter>
      <app-plant-filter
        class="filter-input"
        [selection]="filter.plants"
        (selectionChange)="onPlantSelectionChange($event)"
      ></app-plant-filter>
      <app-aliment-filter
        class="filter-input"
        [selection]="filter.aliments"
        (selectionChange)="onAlimentSelectionChange($event)"
      ></app-aliment-filter>
      <app-variety-filter
        class="filter-input"
        [selectedAliments]="filter.aliments"
        (selectionChange)="onVarietySelectionChange($event)"
      ></app-variety-filter>
      <app-product-filter
        class="filter-input"
        [selection]="filter.products"
        (selectionChange)="onProductSelectionChange($event)"
      ></app-product-filter>
      <app-claim-filter
        class="filter-input"
        [selection]="filter.claims"
        (selectionChange)="onClaimSelectionChange($event)"
      ></app-claim-filter>
      <app-state-filter
        class="filter-input"
        [selection]="filter.states"
        (selectionChange)="onStateSelectionChange($event)"
      ></app-state-filter>
      <app-supplier-filter
        class="filter-input"
        [selection]="filter.suppliers"
        (selectionChange)="onSupplierSelectionChange($event)"
      ></app-supplier-filter>
      <app-origin-filter
        class="filter-input"
        [selection]="filter.origins"
        (selectionChange)="onOriginSelectionChange($event)"
      ></app-origin-filter>
      <app-client-filter
        class="filter-input"
        [selection]="filter.clients"
        (selectionChange)="onClientSelectionChange($event)"
      ></app-client-filter>
      <batch-filter
        class="filter-input"
        (batchValueChange)="onBatchValueChange($event)"
      ></batch-filter>
      <app-date-filter
        class="filter-input"
        [dateRange]="filter.dateRange"
        (dateChange)="onDateRangeChange($event)"
      ></app-date-filter>
    </div>
    <div class="filter-group">
      <!-- <app-search-bar class="filter-search"></app-search-bar> -->
    </div>

    <mat-card-actions>
      <div class="mat-actions-container">
        <button mat-button (click)="onClearFilters()">Clear</button>
        <button mat-fab extended color="primary" (click)="onApplyFilter()">
          <mat-icon>filter_alt</mat-icon>
          Apply
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</section>
