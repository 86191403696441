import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AnalysesService } from '@solverml/api';
import { lastValueFrom } from 'rxjs';
import { DragDropFilesComponent } from 'src/app/fragments/drag-drop-files/drag-drop-files.component';
import { PendingDocumentsListComponent } from './pending-documents-list/pending-documents-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { LastAnalysesListComponent } from './last-analyses-list/last-analyses-list.component';

// ENum
enum UploadProgressStatus {
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Ready = 'Ready',
  Error = 'Error',
}

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    DragDropFilesComponent,
    PendingDocumentsListComponent,
    LastAnalysesListComponent,
  ],
})
export class FileUploadComponent {
  files: File[] = [];
  fileStatus = UploadProgressStatus;
  progressStatus: UploadProgressStatus = UploadProgressStatus.Ready;

  lastAnalysesPanelOpened = false;

  constructor(private analysesService: AnalysesService) {}

  onFilesDropped(files: File[]): void {
    this.files = files;
  }

  onCloseConfirmModal(): void {
    this.emptyFiles();
    this.progressStatus = UploadProgressStatus.Ready;
    // this.router.navigate(['/results']);
  }

  async uploadFiles() {
    this.progressStatus = UploadProgressStatus.Uploading;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await lastValueFrom(
      this.analysesService.analysesProcessAnalysis(this.files)
    );
    this.progressStatus = UploadProgressStatus.Uploaded;
  }

  private emptyFiles() {
    this.files.length = 0;
  }

  lastAnalysesOpenChange(panelOpened: boolean) {
    this.lastAnalysesPanelOpened = panelOpened;
  }
}
