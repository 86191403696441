import { lastValueFrom } from 'rxjs';
import { AfterViewInit, Component } from '@angular/core';
import {
  AnalysesService,
  AnalysisBodyFilter,
  PlantResponseDTO,
  AnalysisResponseDTO,
  AnalysisPerPlantResponseDTO,
  MinimalAnalysisResponseDTO,
} from '@solverml/api';

import { AnalysisPreviewListComponent } from './analysis-result-list/analysis-result-list.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { AnalysisFilterComponent } from './analysis-filter/analysis-filter.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { FilterDTO } from './analysis-filter/analysis-filter.component';
import { dateFormat } from './analysis-filter/date-filter/date-formatter';
import { LabAnalysisService } from 'src/app/services/lab-analysis.service';

interface AnalysisByPlant {
  [plantId: string]: MinimalAnalysisResponseDTO[];
}

@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AnalysisFilterComponent,
    AnalysisPreviewListComponent,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
  ],
})
export class ResultadosComponent implements AfterViewInit {
  analyses: AnalysisByPlant = {}; // Analyses grouped by plant (with filters and whole analysis data)
  analysisCountPerPlant: { [plantId: string]: number } = {}; // Number of analyses per plant (without filters)
  areAnalysesLoaded = false;
  isDeletingAnalysis = false;

  filter: FilterDTO = new FilterDTO();
  plants: PlantResponseDTO[] = [];

  openedPanelId: string | null = null;

  constructor(
    private readonly labAnalysesService: LabAnalysisService,
    private readonly analysesService: AnalysesService
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.refreshDocuments();
  }

  async onDownloadClick() {
    const analysisBodyFilter: AnalysisBodyFilter = {
      analysis_types: this.filter.analysis,
      plant_ids: this.filter.plants?.map((plant) => plant.id),
      aliment_ids: this.filter.aliments?.map((aliment) => aliment.id),
      variety_ids: this.filter.varieties?.map((variety) => variety.id),
      product_ids: this.filter.products?.map((product) => product.id),
      claim_ids: this.filter.claims?.map((claim) => claim.id),
      state_ids: this.filter.states?.map((state) => state.id),
      supplier_ids: this.filter.suppliers?.map((supplier) => supplier.id),
      origin_ids: this.filter.origins?.map((origin) => origin.id),
      client_ids: this.filter.clients?.map((client) => client.id),
      batch: this.filter.batch,
      start_date: this.filter.dateRange.start
        ? dateFormat(this.filter.dateRange.start)
        : undefined,
      end_date: this.filter.dateRange.end
        ? dateFormat(this.filter.dateRange.end)
        : undefined,
    };

    await lastValueFrom(
      this.analysesService.analysesGetExcelResult(analysisBodyFilter)
    );
  }

  async deleteAnalysis(
    doc: AnalysisResponseDTO | MinimalAnalysisResponseDTO,
    plantId: string
  ) {
    this.isDeletingAnalysis = true;

    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay

    await lastValueFrom(this.analysesService.analysesDeleteAnalysis(doc.id));

    // Optimistically remove the analysis from the list to avoid requesting the server again
    this.analyses[plantId] = this.analyses[plantId].filter(
      (analysis) => analysis.id !== doc.id
    );

    this.isDeletingAnalysis = false;
  }

  hasAnyAnalysisFiltered() {
    //<h1 *ngFor="let plant of plants">{{ analyses[plant.id].length }}</h1>
    for (let plant of this.plants) {
      if (this.analyses[plant.id].length > 0) {
        return true;
      }
    }
    return false;
  }

  onFilterChange(filter: FilterDTO) {
    this.filter = filter;
    this.refreshDocuments();
  }

  onPanelOpen(id: string) {
    this.openedPanelId = id;
  }

  private refreshDocuments(): void {
    this.areAnalysesLoaded = false;
    setTimeout(async () => {
      await Promise.all([
        await this.loadAnalysisCountPerPlant(),
        await this.loadDocuments(),
      ]);
      this.areAnalysesLoaded = true;
    });
  }

  private async loadAnalysisCountPerPlant(): Promise<void> {
    const analysesCountPerPlant: AnalysisPerPlantResponseDTO[] =
      await this.labAnalysesService.getAnalysisCountPerPlant();

    this.analysisCountPerPlant = {};
    analysesCountPerPlant.forEach((p) => {
      this.analysisCountPerPlant[p.plant] = p.analysis_count;
    });
  }

  private async loadDocuments(): Promise<void> {
    const analysesBodyFilter: AnalysisBodyFilter = {
      analysis_types: this.filter.analysis,
      plant_ids: this.filter.plants?.map((plant) => plant.id),
      client_ids: this.filter.clients?.map((client) => client.id),
      origin_ids: this.filter.origins?.map((origin) => origin.id),
      supplier_ids: this.filter.suppliers?.map((supplier) => supplier.id),
      variety_ids: this.filter.varieties?.map((variety) => variety.id),
      state_ids: this.filter.states?.map((state) => state.id),
      claim_ids: this.filter.claims?.map((claim) => claim.id),
      product_ids: this.filter.products?.map((product) => product.id),
      aliment_ids: this.filter.aliments?.map((aliment) => aliment.id),
      batch: this.filter.batch,
      start_date: this.filter.dateRange.start
        ? dateFormat(this.filter.dateRange.start)
        : undefined,
      end_date: this.filter.dateRange.end
        ? dateFormat(this.filter.dateRange.end)
        : undefined,
    };

    await this.filterDisplayedPlants(); // keep only the plants that are selected in the filter

    // Get all analyses
    let analyses: MinimalAnalysisResponseDTO[] = null;

    while (analyses === null || analyses === undefined) {
      // If connection fails, try again
      analyses = await this.labAnalysesService.getAnalyses(analysesBodyFilter);
    }

    this.analyses = {};

    // Group analyses by plant
    for (const plant of this.plants) {
      this.analyses[plant.id] = [];
    }

    for (const analysis of analyses) {
      // If plant is 'Other', add the analysis to OTHER plant
      if (!analysis.plant_id) {
        this.analyses['OTHER'] = this.analyses['OTHER'] || [];
        this.analyses['OTHER'].push(analysis);
      } else if (this.analyses[analysis.plant_id]) {
        this.analyses[analysis.plant_id].push(analysis);
      }
    }
  }

  private async filterDisplayedPlants() {
    // Get all available plants
    this.plants = await this.labAnalysesService.getPlants();

    // Keep only the plants that are selected in the filter
    if (this.filter.plants.length > 0) {
      this.plants = this.plants.filter((plant) =>
        this.filter.plants?.some((filterPlant) => filterPlant.id === plant.id)
      );
    }
  }
}
