import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { delay, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService, LoginSubscriber } from './services/auth.service';
import {
  NotificationManagerService,
  NotificationSubscriber,
} from './services/notification-manager.service';
import { WebsocketManagerService } from './services/websocket-manager.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements LoginSubscriber, NotificationSubscriber {
  @ViewChild('sidenav')
  sidenav!: MatSidenav;
  fullScreen: boolean = false;
  currentRoute: any = null;
  notificationViewVisible: boolean = false;
  notificationButtonAlreadyClicked: boolean = false;
  notificationViewFullScreen: boolean = false;
  thereAreNotifications: boolean = false;

  constructor(
    private router: Router,
    private observer: BreakpointObserver,
    private authService: AuthService,
    private websocketManagerService: WebsocketManagerService,
    private notificationService: NotificationManagerService
  ) { }

  /**
   * Se inicializa el sidenav, añadiendo reglas en función del tamaño
   * de pantalla.
   */
  initSidenav() {
    this.observer
      .observe(['(max-width: 55rem)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches && this.sidenav) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
          this.notificationViewFullScreen = false;
        } else if (this.sidenav) {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });

    // Observe the sidenav mode if changed, then trigger resize
    // event for certain components
    this.sidenav?.openedChange.pipe(untilDestroyed(this)).subscribe((res) => {
      document.dispatchEvent(new Event('resize'));
    });
  }

  /**
   * Inicializa el sidenav, y establece cambios cuando estemos en la pantalla
   * de login.
   */
  ngOnInit(): void {
    this.initSidenav();

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        const fullScreen = !this.authService.isLoggedIn();
        if (fullScreen !== this.fullScreen) {
          this.fullScreen = fullScreen;
          this.initSidenav();
        }
      });

    // Subscribe to the login service
    this.authService.subscribe(this);
  }

  onLoginSuccess(): void {
    this.websocketManagerService.initWebsocket();
    this.websocketManagerService.subscribe(
      'notification',
      this.notificationService
    );
    this.notificationService.subscribe(this);
  }

  onLogout(): void {
    this.websocketManagerService.destroyWebsocket();
  }

  onBellIconClick() {
    this.setNotificationView(!this.notificationViewVisible);
  }

  setNotificationView(value: boolean) {
    this.notificationViewVisible = value;
    this.notificationViewFullScreen = false;
    this.notificationButtonAlreadyClicked = true;
  }

  onNotificationCenterUpdate(): void {
    const notifications = this.notificationService.getNotifications();

    // check if there are notifications
    this.thereAreNotifications = notifications.length > 0;

    // if there are not notifications, minimize the notification view
    if (!this.thereAreNotifications) {
      this.notificationViewFullScreen = false;
    }
  }

  closeNotificationView() {
    this.setNotificationView(false);
  }

  toggleFullScreen($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.notificationViewFullScreen = !this.notificationViewFullScreen;
  }

  markAllAsRead($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.notificationService.markAllAsRead();
  }

  deleteAllRead($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.notificationService.deleteAllRead();
  }
}
