<section>
  <div class="dropzone" (drop)="onFiledrop($event)">
    <div class="dropzone-content" *ngIf="isDroping">
      <div class="dropzone-icon gradient-animation">
        <div class="dropzone-icon-container">
          <mat-icon> cloud_upload </mat-icon>
        </div>
        <div class="dropzone-icon-container">
          <span class="label"> Drop your file(s) here </span>
        </div>
      </div>
    </div>
  </div>
  <div class="dropzone-visible">
    <input
      type="file"
      accept="allowedTypes"
      multiple="allowMultipleFiles"
      (change)="onFileSelected($event)"
      #fileDropRef
    />
    <label *ngIf="!label; else customLabel" for="fileDropRef">
      Drag and drop your files here or <b class="bold-blue">click here</b>
    </label>
    <ng-template #customLabel for="fileDropRef">{{ label }}</ng-template>
    <div class="allowed-types">
      Allowed file types:
      <b>{{ allowedList.toUpperCase().replace(".", "") }}</b>
    </div>
    <div *ngIf="incorrectInput" class="error-alert">{{ errorMessage }}</div>
  </div>

  <div *ngIf="files && files.length > 0">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Size</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let file of files; let i = index">
          <td>{{ formatFileNameSize(file.name) }}</td>
          <td>{{ formatBytes(file.size) }}</td>
          <td>
            <button mat-icon-button (click)="viewFile(i)">
              <mat-icon> visibility </mat-icon>
            </button>
          </td>
          <td>
            <button mat-icon-button (click)="deleteFile(i)">
              <mat-icon> delete </mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
