<section>
  <mat-form-field>
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start date" [value]="startDate" (dateChange)="onStartDateChange($event)">
      <input matEndDate placeholder="End date" [value]="endDate" (dateChange)="onEndDateChange($event)">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</section>
