import { Injectable } from '@angular/core';
import { WebsocketSubscriber } from './websocket-manager.service';
import { NotificationResponse, NotificationsService } from '@solverml/api';
import { lastValueFrom } from 'rxjs';

export interface NotificationSubscriber {
  /**
   * This method is called when the notification center updates the notifications
   */
  onNotificationCenterUpdate(): void;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationManagerService implements WebsocketSubscriber {

  static subscribers: NotificationSubscriber[] = [];

  notifications: NotificationResponse[] = [];

  constructor(private notificationsService: NotificationsService) { }

  // If the websocket connection updates the notifications topic fetch the notifications
  onWebsocketUpdate(): void {
    this.fetchNotifications();
  }

  // Different components can be subscribed to the notification service such as the notification center or the navbar
  subscribe(subscriber: NotificationSubscriber): void {
    NotificationManagerService.subscribers.push(subscriber);
    subscriber.onNotificationCenterUpdate();
  }

  // Unsubscribe a component from the notification service
  unsubscribe(subscriber: NotificationSubscriber): void {
    NotificationManagerService.subscribers =
      NotificationManagerService.subscribers.filter((s) => s !== subscriber);
  }

  /**
   * Get the notifications
   * @returns A list containing the notification objects
   */
  getNotifications(): NotificationResponse[] {
    return this.notifications;
  }

  /**
   * Fetch the notifications from the server and update the notifications list
   */
  async fetchNotifications(): Promise<void> {
    this.notifications = await lastValueFrom(
      this.notificationsService.notificationsGetNotifications()
    );

    //Sort notifications by date
    this.notifications.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    // Notify to all the components subscribed to the notification service that the notifications have been updated
    // (navbar, notification center, etc)
    await this.notifySubscribers();
  }

  /**
   *
   * @param id Notification id
   * @param read New read status
   */
  async setRead(id: number, read: boolean): Promise<void> {
    // Update the notification in the server
    await lastValueFrom(
      this.notificationsService.notificationsUpdateNotification({
        id: id,
        read: read,
      })
    );
  }

  markAllAsRead() {

    const readPromise: Promise<void>[] = [];

    // Mark all the notifications as read
    this.notifications.forEach((n) => {
      if (!n.read) {
        readPromise.push(this.setRead(n.id, true));
      }
    });

    Promise.all(readPromise);

  }


  /**
   * Send a notification to the server
   * @param id The id of the user that will receive the notification
   * @param title The title of the notification
   * @param description The description of the notification
   * @param read The read status of the notification
   * @param icon The icon of the notification
   * @param file The file attached to the notification
   */
  async sendNotification(
    user_id: string,
    title: string,
    description: string,
    read: boolean = false,
    icon: string = 'info',
    file: File | undefined = undefined
  ): Promise<void> {
    await lastValueFrom(
      this.notificationsService.notificationsCreateNotification(
        user_id,
        title,
        description,
        read,
        icon,
        file
      )
    );
  }



  /**
   * Delete a notification that belongs to the user
   * @param id The id of the notification
   */
  async deleteNotification(id: number): Promise<void> {
    await lastValueFrom(
      this.notificationsService.notificationsDeleteNotification(id)
    );
  }

  deleteAllRead() {

    const deletePromise: Promise<void>[] = [];

    // Delete all the read notifications
    this.notifications.forEach((n) => {
      if (n.read) {
        deletePromise.push(this.deleteNotification(n.id));
      }
    });

    Promise.all(deletePromise);

  }


  /**
   * Download the attachment of a notification directly from the server to the client
   *
   * @param id The id of the notification
   * @returns A empty promise
   */
  async downloadAttachment(id: number): Promise<void> {
    const response = await lastValueFrom(
      this.notificationsService.notificationsGetAttachment(
        // Get the attachment from the server
        id, // Notification id
        'response', // We want not only the body but also the headers
        false, // We don't want to report the progress
        { httpHeaderAccept: 'application/octet-stream' } as any // We want the body to be a blob/file
      )
    );

    const file: File = response.body as File; // The body is the file
    const headers = response.headers; // The headers contain the filename and the content-disposition

    // Create a virtual URL for the file and download it
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download =
      headers.get('content-disposition')?.split('filename=')[1] || 'file';
    a.click(); // Simulate a click on the link to download the file
    window.URL.revokeObjectURL(url); // Release the URL
  }

  // Notify all the components subscribed to the notification service that the notifications have been updated
  // (navbar, notification center, etc)
  private async notifySubscribers(): Promise<void> {
    NotificationManagerService.subscribers.forEach((s) =>
      s.onNotificationCenterUpdate()
    );
  }
}
