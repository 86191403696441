<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ label }}</mat-label>
    <mat-select [value]="selectedOption" [disabled]="isDisabled" (valueChange)="onValueChange($event)">
      <mat-option *ngIf="selectedOption" [value]="undefined">
        None
      </mat-option>
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{option.viewValue}}
      </mat-option>
    </mat-select>
    
  </mat-form-field>
</form>
