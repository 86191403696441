import { NgIf, NgFor, NgClass } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  Column,
  ManageTableComponent,
} from '../../manage-table/manage-table.component';
import { AlimentResponseDTO, AlimentsService } from '@solverml/api';
import { lastValueFrom } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LabAnalysisService } from 'src/app/services/lab-analysis.service';

@Component({
  selector: 'app-manage-aliments',
  templateUrl: './manage-aliments.component.html',
  styleUrls: ['./manage-aliments.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, ManageTableComponent],
})
export class ManageAlimentsComponent implements OnInit {
  rawData: AlimentResponseDTO[] = [];
  columns: Column[] = [
    { columnId: 'id', columnName: 'ID' },
    { columnId: 'name', columnName: 'Name' },
  ];

  isLoading = false;

  constructor(
    private readonly alimentsService: AlimentsService,
    private readonly labAnalysisService: LabAnalysisService,
    private readonly dialog: MatDialog
  ) {}

  async refreshData() {
    this.isLoading = true;
    await new Promise((resolve) => setTimeout(resolve)); // Allow the spinner to show up
    this.rawData = [...(await this.labAnalysisService.getAliments())];
    this.rawData = this.rawData.filter(
      (item) => !item.id.toLowerCase().includes('other')
    );
    this.isLoading = false;
  }

  async ngOnInit() {
    await this.refreshData();
  }

  async onCreate() {
    const validator = (data: AlimentResponseDTO) => {
      const errors: { [key: string]: string } = {};

      if (!data.id) {
        errors['id_required'] = 'ID is required';
      }

      if (this.rawData.find((item) => item.id === data.id)) {
        errors['id_unique'] = 'ID is already in use';
      }

      if (data.id.toLowerCase().includes('other')) {
        errors['id_other'] = 'ID cannot contain "other"';
      }

      if (!data.name) {
        errors['name_required'] = 'Name value is empty';
      }

      return errors;
    };

    const result: AlimentResponseDTO | undefined = await this.openDialog(
      'Create Aliment',
      {
        id: '',
        name: '',
      },
      false,
      false,
      validator
    );

    if (!result) {
      return;
    }

    const { id, name } = result;

    await lastValueFrom(this.alimentsService.alimentsCreateAliment(id, name));

    this.refreshData();
  }

  async onEdit(item: AlimentResponseDTO) {
    const result: AlimentResponseDTO | undefined = await this.openDialog(
      'Edit Aliment',
      JSON.parse(JSON.stringify(item)),
      true
    );

    if (!result) {
      return;
    }

    const { id, name } = result;

    await lastValueFrom(this.alimentsService.alimentsUpdateAliment(id, name));

    this.refreshData();
  }

  async onDelete(item: AlimentResponseDTO) {
    const result: AlimentResponseDTO | undefined = await this.openDialog(
      'Delete Aliment',
      item,
      false,
      true
    );

    if (!result) {
      return;
    }

    const { id } = result;

    await lastValueFrom(this.alimentsService.alimentsRemoveAliment(id));

    this.refreshData();
  }

  async openDialog(
    title: string,
    data: AlimentResponseDTO,
    isUpdate = false,
    isDelete = false,
    isValid?: (data: AlimentResponseDTO) => { [key: string]: string }
  ) {
    const dialogRef = this.dialog.open(DialogManageAlimentsComponent, {
      data: {
        data: data,
        title: title,
        isUpdate: isUpdate,
        isDelete: isDelete,
        isValid: isValid,
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    return result?.data;
  }
}

@Component({
  selector: 'app-dialog-manage-aliments',
  templateUrl: 'dialog.html',
  styleUrls: ['./manage-aliments.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatTooltipModule,
  ],
})
export class DialogManageAlimentsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: AlimentResponseDTO | undefined;
      title: string;
      isUpdate?: boolean;
      isDelete?: boolean;
      isValid?: (data: AlimentResponseDTO) => { [key: string]: string };
    },

    private readonly dialogRef: MatDialogRef<DialogManageAlimentsComponent>
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  isDisabled(): boolean {
    const errors = this.data.isValid?.(this.data.data) ?? {};
    return Object.keys(errors).length > 0;
  }

  getButtonTooltip(): string {
    const errors = this.data.isValid?.(this.data.data) ?? {};
    return Object.values(errors)[0] ?? '';
  }
}
