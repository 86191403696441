import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

const ADMIN_ONLY_ROUTES = ['/administrar'];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent {
  @Input()
  sidenav!: MatSidenav;

  selected_route: string = '';

  sidebar_routes = [
    {
      url: '/calculator',
      icon: 'calculate',
      text: 'Name calculator',
    },
    {
      url: '/upload',
      icon: 'upload_file',
      text: 'Upload files',
    },
    {
      url: '/results',
      icon: 'analytics',
      text: 'Results',
    },
    {
      url: '/administrar',
      icon: 'manage_accounts',
      text: 'Management',
    },
  ];

  /**
   * Método donde se carga los permisos del usuario y se obtiene la ruta
   * seleccionada actualmente.
   *
   * @param router Objeto de Angular que nos proporciona todo lo relacionado
   * con el enrutamiento
   * @param sessionService Servicio utilizado para obtener los permisos del
   * usuario
   */
  constructor(private router: Router, private authService: AuthService) {
    // Se suscribe a los eventos del router y actualiza la ruta seleccionada
    this.router.events.subscribe((val) => {
      this.selected_route = this.router.url;
    });
  }

  /**
   * Cierra el sidebar si tenemos indicado el modo over.
   */
  closeSidebarModeOver(): void {
    if (this.sidenav.mode === 'over') {
      this.sidenav.close();
    }
  }

  getNotAdminRoutes(): any[] {
    return this.sidebar_routes.filter((route) => !ADMIN_ONLY_ROUTES.includes(route.url) && this.displayRoute(route.url));
  }

  getAdminRoutes(): any[] {
    return this.sidebar_routes.filter((route) => ADMIN_ONLY_ROUTES.includes(route.url) && this.displayRoute(route.url));
  }

  private displayRoute(route: string): boolean {
    let allowed_route = true;

    

    if (
      !this.authService.getRoles().includes('admin_role') && // If user is not admin
      ADMIN_ONLY_ROUTES.includes(route) // And route is admin only
    ) {
      allowed_route = false; // Then don't display route
    }

    return allowed_route;
  }
}
