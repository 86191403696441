<!-- Aplicación -->

<div *ngIf="!fullScreen">
  <!-- Navbar de la aplicación: botón de sidebar, icono de notificaciones y
        detalles del usuario -->
  <app-navbar
    [sidenav]="sidenav"
    (bellIconClick)="onBellIconClick()"
  ></app-navbar>

  <div
    *ngIf="notificationButtonAlreadyClicked"
    [ngClass]="{
      'notification-view': notificationViewVisible,
      'notification-view-out': !notificationViewVisible,
      'notification-view-fullscreen': notificationViewFullScreen
    }"
  >
    <app-notification-center
      style="width: 100%"
      class="notification-center"
      [(isOpen)]="notificationViewVisible"
      [isFullScreen]="notificationViewFullScreen"
      (onCloseNotificationCenter)="closeNotificationView()"
    ></app-notification-center>

    <div *ngIf="thereAreNotifications" class="action_buttons">
      <div class="action_button_group action_button_group-left">
        <div class="btn" (click)="toggleFullScreen($event)">
          <mat-icon *ngIf="!notificationViewFullScreen">open_in_full</mat-icon>
          <p style="margin: 0" *ngIf="!notificationViewFullScreen">
            &nbsp; maximise
          </p>

          <mat-icon *ngIf="notificationViewFullScreen"
            >close_fullscreen</mat-icon
          >
          <p style="margin: 0" *ngIf="notificationViewFullScreen">
            &nbsp; minimise
          </p>
        </div>
      </div>
      <div class="action_button_group">
        <div
          class="btn"
          *ngIf="notificationViewFullScreen"
          (click)="markAllAsRead($event)"
        >
          <mat-icon> mark_email_read </mat-icon>
          <p style="margin: 0">&nbsp; Mark all as read</p>
        </div>
        <div
          class="btn"
          *ngIf="notificationViewFullScreen"
          (click)="deleteAllRead($event)"
        >
          <mat-icon> delete_sweep </mat-icon>
          <p style="margin: 0">&nbsp; Delete all read</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Contenedor del sidenav para evitar que aparezcan scrollbars -->
  <mat-sidenav-container>
    <!-- Sidenav -->
    <mat-sidenav class="mat-elevation-z8" #sidenav>
      <app-sidebar [sidenav]="sidenav"></app-sidebar>
    </mat-sidenav>

    <!-- Contenido de la aplicación -->
    <mat-sidenav-content class="pl-5 pr-5">
      <div class="content mat-elevation-z0">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div *ngIf="fullScreen">
  <!-- Contenido de la aplicación -->
  <router-outlet></router-outlet>
</div>
