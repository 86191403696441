import { AnalysisResponseDTO, AnalysesService } from '@solverml/api';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

interface Document {
  file: File | null;
  name: string | null;
  size: string | null;
}

@Component({
  selector: 'app-analisys-document-preview',
  templateUrl: './analisys-document-preview.component.html',
  styleUrls: ['./analisys-document-preview.component.scss'],
  standalone: true,
  imports: [NgClass, NgFor, NgIf, MatProgressSpinnerModule],
})
export class AnalisysDocumentPreviewComponent implements AfterViewInit {
  @Input() set analysis(analysis: AnalysisResponseDTO) {
    this.showAnalysisDocument(analysis);
  }

  isDocumentLoaded = false;
  componentId: string =
    'file-preview-frame-' + Math.random().toString(36).substring(7);

  constructor(private analysesService: AnalysesService) {}

  ngAfterViewInit(): void {}

  private async showAnalysisDocument(
    analysis: AnalysisResponseDTO
  ): Promise<void> {
    this.isDocumentLoaded = false;
    await new Promise((resolve) => setTimeout(resolve));

    const doc = await this.downloadAnalysisDocument(analysis);
    if (doc) {
      this.createDocumentIFrame(doc as Document);
    }

    this.isDocumentLoaded = true;
  }

  private async downloadAnalysisDocument(
    analysis: AnalysisResponseDTO
  ): Promise<Document | undefined> {
    if (!analysis) {
      return undefined;
    }

    const response = await lastValueFrom(
      this.analysesService.analysesGetAnalysisDocument(
        `storage/analyses/${analysis.filename}`,
        'response', // We want not only the body but also the headers
        false, // We don't want to report the progress
        {
          httpHeaderAccept: 'application/octet-stream',
        } as any // We want the body to be a blob/file
      )
    );

    const file: File = response.body as File; // The body is the file

    const doc: Document = {
      file: file,
      name: analysis.id,
      size: (file.size / 1024).toFixed(2) + ' KB',
    };

    return doc;
  }

  private createDocumentIFrame(doc: Document): void {
    setTimeout(() => {
      let container: any | null = null;

      while (!container) {
        container = document.getElementById(this.componentId);
      }

      container.childNodes.forEach((child: any) => {
        container.removeChild(child);
      });

      const filename = doc.name;

      const file = new File([doc.file!], filename, {
        type: 'application/pdf',
      });
      const iframe = document.createElement('iframe');
      iframe.src = URL.createObjectURL(file) + '#zoom=auto';
      iframe.style.border = 'none';
      iframe.width = '100%';
      iframe.height = '100%';
      container?.appendChild(iframe);
    });
  }
}
