import {
  AnalysesService,
  AnalysisBodyCreate,
  AnalysisResponseDTO,
} from '@solverml/api';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import { AnalysisMetadataTableComponent } from './analysis-metadata-table/analysis-metadata-table.component'; // Column Definition Type Interface
import { AnalysisResultTableComponent } from './analysis-result-table/analysis-result-table.component';
import { MatButtonModule } from '@angular/material/button';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-analysis-result-data',
  templateUrl: './analysis-result-data.component.html',
  styleUrls: ['./analysis-result-data.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    MatTabsModule,
    AgGridAngular,
    AnalysisMetadataTableComponent,
    AnalysisResultTableComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
})
export class AnalysisResultDataComponent implements OnInit {
  @Input() set analysis(value: AnalysisResponseDTO | null) {
    this.modified_analysis = value; // Propagate by reference

    // Keep a copy of the original analysis without modifying it by reference
    this.original_analysis = JSON.parse(
      JSON.stringify(value)
    ) as AnalysisResponseDTO;
    this.ngOnInit();
  }

  @Output() onAnalysisRemoved: EventEmitter<AnalysisResponseDTO> =
    new EventEmitter<AnalysisResponseDTO>();

  original_analysis: AnalysisResponseDTO | null = null;
  modified_analysis: AnalysisResponseDTO | null = null;
  changed: boolean = false;
  isChanging: boolean = false;

  analysisTypes: string[] = [];

  constructor(
    private readonly analysisService: AnalysesService, // Service to interact with the API
    private readonly authService: AuthService // Service to interact with the API
  ) {}

  ngOnInit() {
    if (this.modified_analysis) {
      this.analysisTypes = [
        ...new Set(
          this.modified_analysis.results.map((result) => result.analysis_type)
        ),
      ];
    }
  }

  toTitleCase(str: string): string {
    // If the string starts with UpperCase, leave it as it is
    if (str.match(/^[A-Z]/)) {
      return str;
    }

    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  onAnalysisChange() {
    this.changed =
      JSON.stringify(this.original_analysis) !==
      JSON.stringify(this.modified_analysis);
  }

  async onSaveChangesClick() {
    this.isChanging = true;
    await new Promise((resolve) => setTimeout(resolve, 1));

    this.original_analysis = JSON.parse(
      JSON.stringify(this.modified_analysis)
    ) as AnalysisResponseDTO;

    const analysisBodyCreate: AnalysisBodyCreate = {
      analysis_id: this.modified_analysis?.id,
      filename: this.modified_analysis?.filename,
      reference: this.modified_analysis?.reference,
      aliment_id: this.modified_analysis?.aliment?.id,
      product_id: this.modified_analysis?.product?.id,
      state_id: this.modified_analysis?.state?.id,
      plant_id: this.modified_analysis?.plant?.id,
      variety_id: this.modified_analysis?.variety?.id,
      claim_id: this.modified_analysis?.claim?.id,
      supplier_id: this.modified_analysis?.supplier?.id,
      origin_id: this.modified_analysis?.origin?.id,
      client_id: this.modified_analysis?.client?.id,
      start_date: this.modified_analysis?.start_date,
      registration_date: this.modified_analysis?.registration_date,
      end_date: this.modified_analysis?.end_date,
      laboratory: this.modified_analysis?.laboratory,
      delivery_address: this.modified_analysis?.delivery_address,
      delivery_receiver: this.modified_analysis?.delivery_receiver,
      batch: this.modified_analysis?.batch,
      results: this.modified_analysis.results,
    };

    await lastValueFrom(
      this.analysisService.analysesUpdateAnalysis(analysisBodyCreate)
    );

    // Reset the changed flag
    this.onAnalysisChange();
    this.isChanging = false;
  }

  onDiscardChangesCLick() {
    this.modified_analysis = JSON.parse(
      JSON.stringify(this.original_analysis)
    ) as AnalysisResponseDTO;
    this.onAnalysisChange(); // Reset the changed flag
  }

  async onRemoveAnalysisClick() {
    const confirmed = window.confirm(
      'Are you sure you want to delete this analysis?'
    );
    console.log(confirmed);
    if (confirmed) {
      this.onAnalysisRemoved.emit(this.modified_analysis);
    }
  }

  allowEditActions(): boolean {
    return this.authService.getRoles().includes('admin_role');
  }
}
