import { NgIf, NgFor, NgClass } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  Column,
  ManageTableComponent,
} from '../../manage-table/manage-table.component';
import { SupplierResponseDTO, SuppliersService } from '@solverml/api';
import { lastValueFrom } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LabAnalysisService } from 'src/app/services/lab-analysis.service';

@Component({
  selector: 'app-manage-suppliers',
  templateUrl: './manage-suppliers.component.html',
  styleUrls: ['./manage-suppliers.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, ManageTableComponent],
})
export class ManageSuppliersComponent implements OnInit {
  rawData: SupplierResponseDTO[] = [];
  columns: Column[] = [
    { columnId: 'id', columnName: 'ID' },
    { columnId: 'description', columnName: 'Description' },
  ];

  isLoading = false;

  constructor(
    private readonly suppliersService: SuppliersService,
    private readonly labAnalysisService: LabAnalysisService,
    private readonly dialog: MatDialog
  ) {}

  async refreshData() {
    this.isLoading = true;
    await new Promise((resolve) => setTimeout(resolve)); // Allow the spinner to show up
    this.rawData = await this.labAnalysisService.getSuppliers();
    this.rawData = this.rawData.filter(
      (item) => !item.id.toLowerCase().includes('other')
    );
    this.isLoading = false;
  }

  async ngOnInit() {
    await this.refreshData();
  }

  async onCreate() {
    const validator = (data: SupplierResponseDTO) => {
      const errors: { [key: string]: string } = {};

      if (!data.id) {
        errors['id_required'] = 'ID is required';
      }

      if (this.rawData.find((item) => item.id === data.id)) {
        errors['id_unique'] = 'ID is already in use';
      }

      if (data.id.toLowerCase().includes('other')) {
        errors['id_other'] = 'ID cannot contain "other"';
      }

      if (!data.description) {
        errors['description_required'] = 'Description value is empty';
      }

      return errors;
    };

    const result: SupplierResponseDTO | undefined = await this.openDialog(
      'Create Supplier',
      { id: '', description: '' },
      false,
      false,
      validator
    );

    if (!result) {
      return;
    }

    const { id, description } = result;

    await lastValueFrom(
      this.suppliersService.suppliersCreateSupplier(id, description)
    );

    this.refreshData();
  }

  async onEdit(item: SupplierResponseDTO) {
    const result: SupplierResponseDTO | undefined = await this.openDialog(
      'Edit Supplier',
      JSON.parse(JSON.stringify(item)),
      true
    );

    if (!result) {
      return;
    }

    const { id, description } = result;

    await lastValueFrom(
      this.suppliersService.suppliersUpdateSupplier(id, description)
    );

    this.refreshData();
  }

  async onDelete(item: SupplierResponseDTO) {
    const result: SupplierResponseDTO | undefined = await this.openDialog(
      'Delete Supplier',
      item,
      false,
      true
    );

    if (!result) {
      return;
    }

    const { id } = result;

    await lastValueFrom(this.suppliersService.suppliersRemoveSupplier(id));

    this.refreshData();
  }

  async openDialog(
    title: string,
    data: SupplierResponseDTO,
    isUpdate = false,
    isDelete = false,
    isValid?: (data: SupplierResponseDTO) => { [key: string]: string }
  ) {
    const dialogRef = this.dialog.open(DialogManageSuppliersComponent, {
      data: {
        data: data,
        title: title,
        isUpdate: isUpdate,
        isDelete: isDelete,
        isValid: isValid,
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    return result?.data;
  }
}

@Component({
  selector: 'app-dialog-manage-suppliers',
  templateUrl: 'dialog.html',
  styleUrls: ['./manage-suppliers.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatTooltipModule,
  ],
})
export class DialogManageSuppliersComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: SupplierResponseDTO | undefined;
      title: string;
      isUpdate?: boolean;
      isDelete?: boolean;
      isValid?: (data: SupplierResponseDTO) => { [key: string]: string };
    },
    private readonly dialogRef: MatDialogRef<DialogManageSuppliersComponent>
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  isDisabled(): boolean {
    const errors = this.data.isValid?.(this.data.data) ?? {};
    return Object.keys(errors).length > 0;
  }

  getButtonTooltip(): string {
    const errors = this.data.isValid?.(this.data.data) ?? {};
    return Object.values(errors)[0] ?? '';
  }
}
