import { NgFor } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LabAnalysisService } from 'src/app/services/lab-analysis.service';

@Component({
  selector: 'app-analysis-type-filter',
  templateUrl: './analysis-type-filter.component.html',
  styleUrls: ['./analysis-type-filter.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
  ],
})
export class AnalysisTypeFilterComponent implements AfterViewInit {
  @Input() set selection(value: string[]) {
    this.formControl.setValue(value);
  }

  @Output() selectionChange = new EventEmitter<string[]>();

  formControl: FormControl = new FormControl('');
  optionList: string[] = [];

  constructor(private readonly labAnalysisService: LabAnalysisService) {}

  async ngAfterViewInit() {
    this.optionList = await this.labAnalysisService.getAnalysisTypes();

    this.formControl.valueChanges.subscribe(() => {
      this.selectionChange.emit(this.formControl.value);
    });
  }

  toTitleCase(str: string): string {

    // If the string starts with UpperCase, leave it as it is
    if (str.match(/^[A-Z]/)) {
      return str;
    }

    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }
}
