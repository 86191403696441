<div class="load-spinner-overlay" *ngIf="isDeletingAnalysis">
  <mat-spinner></mat-spinner>
</div>

<section *ngIf="areAnalysesLoaded" class="fade-in">
  <mat-accordion>
    <mat-expansion-panel
      hideToggle="true"
      expanded="false"
      (opened)="onPanelOpen()"
      (closed)="onPanelClose()"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="plant-title">
            <span class="plant-id"> Analyses processed today </span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <div *ngIf="analyses.length > 0">{{ analyses.length }} analyses</div>
          <div *ngIf="analyses.length === 0">No analyses processed today</div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-analysis-result-list
        *ngIf="analyses.length > 0 && isPanelOpen"
        [analyses]="analyses"
        (deleteAnalysis)="deleteAnalysis($event)"
      ></app-analysis-result-list>
    </mat-expansion-panel>
  </mat-accordion>
</section>
