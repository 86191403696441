import { Component } from '@angular/core';

import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';

import { ManageTableComponent } from './manage-table/manage-table.component';
import { ManageAlimentsComponent } from './entities/manage-aliments/manage-aliments.component';
import { ManageProductsComponent } from './entities/manage-products/manage-products.component';
import { ManageClaimsComponent } from './entities/manage-claims/manage-claims.component';
import { ManageStatesComponent } from './entities/manage-states/manage-states.component';
import { ManageVarietiesComponent } from './entities/manage-varieties/manage-varieties.component';
import { ManageSuppliersComponent } from './entities/manage-suppliers/manage-suppliers.component';
import { ManageOriginsComponent } from './entities/manage-origins/manage-origins.component';
import { ManageClientsComponent } from './entities/manage-clients/manage-clients.component';

@Component({
  selector: 'app-administrar',
  templateUrl: './administrar.component.html',
  styleUrls: ['./administrar.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    MatTabsModule,
    MatProgressSpinnerModule,
    ManageTableComponent,
    ManageAlimentsComponent,
    ManageProductsComponent,
    ManageClaimsComponent,
    ManageStatesComponent,
    ManageVarietiesComponent,
    ManageSuppliersComponent,
    ManageOriginsComponent,
    ManageClientsComponent,
  ],
})
export class AdministrarComponent {
  rawData: any = {};

  constructor() {}
}
