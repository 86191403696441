<section class="notification-center">
  <div
    class="notification-header"
    *ngIf="
      (unreadNotifications.length === 0 && !showReadNotifications) ||
        (unreadNotifications.length === 0 && readNotifications.length === 0);
      else notificationPanel
    "
  >
    <p class="empty-notification">
      <span> 
        You have read all your notifications!
      </span>
      &nbsp;
      <mat-icon>sentiment_very_satisfied</mat-icon>
    </p>
  </div>

  <ng-template #notificationPanel>
    <div class="notification-panel">
      <div *ngFor="let notification of unreadNotifications; index as i">
        <app-notification
          [notification]="notification"
          [showDeleteButton]="isFullScreen"
        ></app-notification>
        <hr *ngIf="i !== unreadNotifications.length - 1" />
      </div>

      <div *ngIf="showReadNotifications">
        <div *ngFor="let notification of readNotifications; index as i">
          <hr *ngIf="unreadNotifications.length > 0 || i > 0" />
          <app-notification
            [notification]="notification"
            [showDeleteButton]="isFullScreen"
          ></app-notification>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="readNotifications.length > 0">
    <div class="show-read-notifications-button-container">
      <button
        *ngIf="!showReadNotifications"
        mat-raised-button
        color="primary"
        (click)="showReadNotifications = !showReadNotifications"
      >
        Mostrar leídas
      </button>
      <button
        *ngIf="showReadNotifications"
        mat-flat-button
        (click)="showReadNotifications = !showReadNotifications"
      >
        Ocultar leídas
      </button>
    </div>
  </div>
</section>
