import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FileUploadComponent } from './pages/upload-files/upload-files.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AdministrarComponent } from './pages/administrar/administrar.component';
import { ResultadosComponent } from './pages/resultados/resultados.component';
import { CalculadoraNombresComponent } from './pages/calculadora-nombres/calculadora-nombres.component';
import { AuthGuard } from './guards/auth.guard';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuard } from './guards/role.guard';
import { NotEnoughPermissionsComponent } from './pages/not-enough-permissions/not-enough-permissions.component';

const AdminRole = 'admin_role';
const UserRole = 'user_role';

/**
 * Se define las rutas de la aplicación, los componentes que se muestran en dichas
 * rutas y en caso de necesitar alguna condición para acceder, el Guard correspondiente.
 */
const routes: Routes = [
  { path: '', redirectTo: '/upload', pathMatch: 'full' }, // Default route
  { path: 'home', redirectTo: '/results', pathMatch: 'full' }, // Route to HomeComponent
  {
    path: 'upload',
    component: FileUploadComponent,
    data: {
      roles: [ UserRole, AdminRole ], // Only users or admins can access this route
    },
    canActivate: [MsalGuard, AuthGuard, RoleGuard],
  }, // Route to upload files
  {
    path: 'calculator',
    component: CalculadoraNombresComponent,
    data: {
      roles: [ UserRole, AdminRole ], // Only users or admins can access this route
    },
    canActivate: [MsalGuard, AuthGuard, RoleGuard],
  }, // Route to HomeComponent
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      roles: [ UserRole, AdminRole ], // Only users or admins can access this route
    },
    canActivate: [MsalGuard, AuthGuard, RoleGuard],
  }, // Route to ProfileComponent
  {
    path: 'results',
    component: ResultadosComponent,
    data: {
      roles: [ UserRole, AdminRole ], // Only users or admins can access this route
    },
    canActivate: [MsalGuard, AuthGuard, RoleGuard],
  }, // Route to CalculadoraComponent
  {
    path: 'administrar',
    component: AdministrarComponent,
    data: {
      roles: [ AdminRole ], // Only admins can access this route
    },
    canActivate: [MsalGuard, AuthGuard, RoleGuard],
  }, // Route to AdministrarComponent
  { path: 'login', component: LoginComponent }, // Route to LoginComponent
  {
    path: 'not-enough-permissions', 
    component: NotEnoughPermissionsComponent,
    canActivate: [MsalGuard, AuthGuard],
  },
  { path: '**', component: NotFoundComponent }, // Route to NotFoundComponent
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
