import {
  AlimentResponseDTO,
  AnalysisResponseDTO,
  ClaimResponseDTO,
  ClientResponseDTO,
  OriginResponseDTO,
  PlantResponseDTO,
  ProductResponseDTO,
  StateResponseDTO,
  SupplierResponseDTO,
  VarietyResponseDTO,
} from '.clients/api-client/dist';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  CellEditorSelectorResult,
  NewValueParams,
} from 'ag-grid-community';
import { CustomCellEditorComponent } from 'src/app/fragments/ag-grig-custom-components/cell-editors/custom-cell-editor/custom-cell-editor.component';
import { LabAnalysisService } from 'src/app/services/lab-analysis.service';

export interface AnalysisMetadata {
  id: string | null;
  filename: string | null;
  reference: string | null;
  aliment: AlimentResponseDTO | null;
  product: ProductResponseDTO | null;
  state: StateResponseDTO | null;
  plant: PlantResponseDTO | null;
  variety: VarietyResponseDTO | null;
  claim: ClaimResponseDTO | null;
  supplier: SupplierResponseDTO | null;
  origin: OriginResponseDTO | null;
  client: ClientResponseDTO | null;
  registration_date: Date | null;
  start_date: Date | null;
  end_date: Date | null;
  laboratory: string | null;
  delivery_address: string | null;
  delivery_receiver: string | null;
  batch: string | null;
}

interface TableRow {
  key: string;
  value: string | Date;
}

const NAN_REPRESENTATION = 'N/A';

@Component({
  selector: 'app-analysis-metadata-table',
  templateUrl: './analysis-metadata-table.component.html',
  styleUrls: ['./analysis-metadata-table.component.scss'],
  standalone: true,
  imports: [CommonModule, AgGridAngular],
})
export class AnalysisMetadataTableComponent implements OnInit {

  @Input() set analysis(value: AnalysisResponseDTO | null) {
    this._analysis = value;
    this.getMetadata();
  }

  @Input() allowEdit: boolean = false;

  @Output() analysisChange: EventEmitter<void> = new EventEmitter<void>();

  _analysis: AnalysisResponseDTO | null = null;

  metadata: AnalysisMetadata | null = null;
  metadatatableRows: TableRow[] = [];
  metadataColDefs: ColDef[] = [];

  aliments: AlimentResponseDTO[] = [];
  products: ProductResponseDTO[] = [];
  states: StateResponseDTO[] = [];
  plants: PlantResponseDTO[] = [];
  varieties: VarietyResponseDTO[] = [];
  claims: ClaimResponseDTO[] = [];
  suppliers: SupplierResponseDTO[] = [];
  origins: OriginResponseDTO[] = [];
  clients: ClientResponseDTO[] = [];


  frameworkComponents: any;

  constructor(private readonly labAnalysisService: LabAnalysisService) {
    this.frameworkComponents = {
      customCellEditor: CustomCellEditorComponent,
    };
  }

  async ngOnInit() {
    this.aliments = await this.labAnalysisService.getAliments();
    this.products = await this.labAnalysisService.getProducts();
    this.states = await this.labAnalysisService.getStates();
    this.plants = await this.labAnalysisService.getPlants();
    this.claims = await this.labAnalysisService.getClaims();
    this.suppliers = await this.labAnalysisService.getSuppliers();
    this.origins = await this.labAnalysisService.getOrigins();
    this.clients = await this.labAnalysisService.getClients();

    const sortPredicate = (a: any, b: any) => {
      if (a.id?.toLowerCase().includes('other')) {
        return -1;
      }
      if (b.id?.toLowerCase().includes('other')) {
        return 1;
      }

      return a.id.localeCompare(b.id);
    };

    this.aliments = this.aliments.sort(sortPredicate);
    this.products = this.products.sort(sortPredicate);
    this.states = this.states.sort(sortPredicate);
    this.plants = this.plants;
    this.claims = this.claims.sort(sortPredicate);
    this.suppliers = this.suppliers.sort(sortPredicate);
    this.origins = this.origins.sort(sortPredicate);
    this.clients = this.clients.sort(sortPredicate);
  }

  getMetadata() {
    this.metadata = {
      id: this._analysis?.id,
      filename: this._analysis?.filename,
      reference: this._analysis?.reference,
      aliment: this._analysis?.aliment,
      product: this._analysis?.product,
      state: this._analysis?.state,
      plant: this._analysis?.plant,
      variety: this._analysis?.variety,
      claim: this._analysis?.claim,
      supplier: this._analysis?.supplier,
      origin: this._analysis?.origin,
      client: this._analysis?.client,
      registration_date: this.strToDateTime(this._analysis?.registration_date),
      start_date: this.strToDateTime(this._analysis?.start_date),
      end_date: this.strToDateTime(this._analysis?.end_date),
      laboratory: this._analysis?.laboratory,
      delivery_address: this._analysis?.delivery_address,
      delivery_receiver: this._analysis?.delivery_receiver,
      batch: this._analysis?.batch,
    };

    this.refreshTable();

    this.metadataColDefs = [
      {
        headerName: 'Metadata',
        field: 'key',
        sortable: true,
        filter: true,
        pinned: 'left',
        valueFormatter: (params: any) =>
          this.toTitleCase(params.value.replace('_', ' ')),
      },
      {
        headerName: 'Value',
        field: 'value',
        sortable: true,
        filter: true,
        flex: 1,
        cellDataType: 'text | object', // Accepts any type of data for the same column
        editable: (params: any) => this.isCellEditable(params),
        valueFormatter: (params: any) => this.valueFormatter(params),
        cellEditor: (params: any) => this.cellEditor(params),
        cellEditorSelector: (params: any) => this.cellEditorSelector(params),
        onCellValueChanged: (event: NewValueParams<any, any>) =>
          this.onCellValueChanged(event),
      },
    ];

    const currentVariety = JSON.parse(JSON.stringify(this.metadata.variety)); // Save the current variety
    this.updateVarieties(); // Update the varieties list
    this.metadata.variety = currentVariety; // Select the current variety in
  }

  private refreshTable(): void {
    this.metadatatableRows = Object.entries(this.metadata).map(
      ([key, value]) => ({
        key: key,
        value: value as string | Date,
      })
    );
  }

  private valueFormatter(params: any): string {
    const key = params.data?.key;
    const value = params.value;

    if (!key) {
      // In edit mode, the key is undefined, so cannot render the value
      return value || NAN_REPRESENTATION;
    }

    if (key.includes('date')) {
      return value
        ? new Date(value).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : NAN_REPRESENTATION;
    }

    if (key === 'aliment') {
      return value ? (value as AlimentResponseDTO).name : NAN_REPRESENTATION;
    }

    if (key === 'product') {
      return value ? (value as ProductResponseDTO).name : NAN_REPRESENTATION;
    }

    if (key === 'state') {
      return value ? (value as StateResponseDTO).name : NAN_REPRESENTATION;
    }

    if (key === 'plant') {
      return value ? (value as PlantResponseDTO).id : NAN_REPRESENTATION;
    }

    if (key === 'variety') {
      return value ? (value as VarietyResponseDTO).name : NAN_REPRESENTATION;
    }

    if (key === 'claim') {
      return value ? (value as ClaimResponseDTO).name : NAN_REPRESENTATION;
    }

    if (key === 'supplier') {
      return value
        ? (value as SupplierResponseDTO).description
        : NAN_REPRESENTATION;
    }

    if (key === 'origin') {
      return value ? (value as OriginResponseDTO).country : NAN_REPRESENTATION;
    }

    if (key === 'client') {
      return value
        ? (value as ClientResponseDTO).description
        : NAN_REPRESENTATION;
    }

    return value;
  }

  private cellEditor(params: any): string | undefined {
    const key = params.data.key;

    if (key.includes('date')) {
      return 'agDateCellEditor';
    }

    return 'agTextCellEditor';
  }

  private isCellEditable(params: any): boolean {
    // If edit is not allowed, globally, then return false
    if (!this.allowEdit) {
      return false;
    }

    return !['id', 'filename'].find((key) => key === params.data.key);
  }

  private cellEditorSelector(
    params: any
  ): CellEditorSelectorResult | undefined {
    const key = params.data.key;

    let component: CellEditorSelectorResult | undefined = {
      component: 'agTextCellEditor',
    };

    if (key === 'aliment') {
      component.component = 'customCellEditor';
      component.params = {
        value: this.metadata.aliment?.name,
        values: [...this.aliments.map((aliment) => aliment.name).sort()],
      };
    } else if (key === 'product') {
      component.component = 'customCellEditor';
      component.params = {
        value: this.metadata.product?.name,
        values: [...this.products.map((product) => product.name)].sort(),
      };
    } else if (key === 'state') {
      component.component = 'customCellEditor';
      component.params = {
        value: this.metadata.state?.name,
        values: [...this.states.map((state) => state.name)].sort(),
      };
    } else if (key === 'plant') {
      component.component = 'customCellEditor';
      component.params = {
        value: this.metadata.plant?.id,
        values: [...this.plants.map((plant) => plant.id)].sort(),
      };
    } else if (key === 'variety') {
      component.component = 'customCellEditor';
      component.params = {
        value: this.metadata.variety?.name,
        values: [...this.varieties.map((variety) => variety.name)].sort(),
      };
    } else if (key === 'claim') {
      component.component = 'customCellEditor';
      component.params = {
        value: this.metadata.claim?.name,
        values: [...this.claims.map((claim) => claim.name)].sort(),
      };
    } else if (key === 'supplier') {
      component.component = 'customCellEditor';
      component.params = {
        value: this.metadata.supplier?.description,
        values: [
          ...this.suppliers.map((supplier) => supplier.description),
        ].sort(),
      };
    } else if (key === 'origin') {
      component.component = 'customCellEditor';
      component.params = {
        value: this.metadata.origin?.country,
        values: [...this.origins.map((origin) => origin.country)].sort(),
      };
    } else if (key === 'client') {
      component.component = 'customCellEditor';
      component.params = {
        value: this.metadata.client?.description,
        values: [...this.clients.map((client) => client.description)].sort(),
      };
    } else if (key.includes('date')) {
      component.component = 'agDateCellEditor';
    }

    return component;
  }

  private onCellValueChanged(event: NewValueParams<any, any>): void {
    const key = event.data.key;
    const value = event.newValue;

    if (!key) {
      return;
    }

    if (key.includes('date')) {
      this.metadata[key] = new Date(value);
    } else if (key === 'aliment') {
      this.metadata[key] = this.aliments.find(
        (aliment) => aliment.name === value
      );
      this.updateVarieties();
    } else if (key === 'product') {
      this.metadata[key] = this.products.find(
        (product) => product.name === value
      );
    } else if (key === 'state') {
      this.metadata[key] = this.states.find((state) => state.name === value);
    } else if (key === 'plant') {
      this.metadata[key] = this.plants.find((plant) => plant.id === value);
    } else if (key === 'variety') {
      this.metadata[key] = this.varieties.find(
        (variety) => variety.name === value
      );
    } else if (key === 'claim') {
      this.metadata[key] = this.claims.find((claim) => claim.name === value);
    } else if (key === 'supplier') {
      this.metadata[key] = this.suppliers.find(
        (supplier) => supplier.description === value
      );
    } else if (key === 'origin') {
      this.metadata[key] = this.origins.find(
        (origin) => origin.country === value
      );
    } else if (key === 'client') {
      this.metadata[key] = this.clients.find(
        (client) => client.description === value
      );
    } else {
      this.metadata[key] = value;
    }

    this._analysis.aliment = this.metadata.aliment;
    this._analysis.product = this.metadata.product;
    this._analysis.state = this.metadata.state;
    this._analysis.plant = this.metadata.plant;
    this._analysis.variety = this.metadata.variety;
    this._analysis.claim = this.metadata.claim;
    this._analysis.supplier = this.metadata.supplier;
    this._analysis.origin = this.metadata.origin;
    this._analysis.client = this.metadata.client;

    this._analysis.registration_date = this.dateTimetoStr(
      this.metadata.registration_date
    );
    this._analysis.reference = this.metadata.reference;
    this._analysis.start_date = this.dateTimetoStr(this.metadata.start_date);
    this._analysis.end_date = this.dateTimetoStr(this.metadata.end_date);
    this._analysis.laboratory = this.metadata.laboratory;
    this._analysis.delivery_address = this.metadata.delivery_address;
    this._analysis.delivery_receiver = this.metadata.delivery_receiver;
    this._analysis.batch = this.metadata.batch;

    this.analysisChange.emit();
  }

  private async updateVarieties(): Promise<void> {
    let alimentId = this.metadata.aliment.id;
    const currentVariety = JSON.parse(JSON.stringify(this.metadata.variety)); // Save the current variety

    this.varieties = await this.labAnalysisService.getVarieties(alimentId);
    this.varieties.sort((a, b) => {
      // Ensure that the 'Other' variety is always at the TOP of the list
      if (a.name?.toLowerCase().includes('other')) {
        return -1;
      }
      if (b.name?.toLowerCase().includes('other')) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });

    // If previously selected variety is not in the new list, select the first one
    if (!this.varieties.find((variety) => variety.id === currentVariety.id)) {
      this.metadata.variety = this.varieties[0];
    }

    this.refreshTable();
  }

  private strToDateTime(str: string | null | undefined): Date | null {
    if (!str) {
      return null;
    }

    const date = new Date(str);
    return date;
  }

  private dateTimetoStr(date: Date | null): string | null {
    if (!date) {
      return null;
    }

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  toTitleCase(str: string): string {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }
}
