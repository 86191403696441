<!-- Parte superior del sidebar donde se encuentra el logo de la empresa
  y el botón de mostrar ocultar el sidebar -->
<div id="branding">
  <a routerLink="/">
    <img
      id="logo_sidebar"
      class="avatar inverse_in_dark_mode"
      src="assets/static/images/logo-dacsa.png"
    />
  </a>

  <div id="close_sidebar">
    <button
      mat-icon-button
      (click)="sidenav.toggle()"
      id="close_sidebar_button"
    >
      <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
    </button>
  </div>
</div>

<!-- Lista de rutas que se mostrarán en el sidebar -->
<div *ngFor="let page of this.getNotAdminRoutes(); let i = index">

  <button
    mat-button
    class="menu-button"
    [ngClass]="{ selected: selected_route == page.url }"
    [routerLink]="page.url"
    (click)="closeSidebarModeOver()"
  >
    <mat-icon>{{ page.icon }}</mat-icon>
    <p class="sidenav-text">{{ page.text }}</p>
  </button>
</div>

<mat-divider></mat-divider>

<div *ngFor="let page of this.getAdminRoutes(); let i = index">
  <button
    mat-button
    class="menu-button"
    [ngClass]="{ selected: selected_route == page.url }"
    [routerLink]="page.url"
    (click)="closeSidebarModeOver()"
  >
    <mat-icon>{{ page.icon }}</mat-icon>
    <p class="sidenav-text">{{ page.text }}</p>
  </button>
</div>

<!-- Footer SolverIA -->
<div class="footer">
  <div class="footer-logos">
    <div class="footer-logo">
      <img
        src="assets/static/images/logo-solver.png"
        class="inverse_in_dark_mode"
      />
      <p>Powered by SolverIA</p>
    </div>
  </div>
</div>
