/**
 * Full Stack FastAPI MARIADB
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Analysis result response DTO.
 */
export interface AnalysisResultResponseDTO { 
    analysis_type: string | null;
    element: string | null;
    value: string | null;
    measurement_unit: string | null;
}

