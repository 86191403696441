import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Component, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-cell-editor',
  templateUrl: 'custom-cell-editor.component.html',
  styleUrls: ['custom-cell-editor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ScrollingModule,
  ],
})
export class CustomCellEditorComponent implements ICellEditorAngularComp {

  private params: any;
  private availableValues: any[];

  public selectedValue: any;
  public searchText: string = '';
  public visibleOptions: string[] = [];

  agInit(params: any): void {
    this.params = params;
    const { value, values } = params;
    this.selectedValue = value;
    this.availableValues = [...values];
    this.visibleOptions = [...values];
    this.searchText = '';

    console.log(this.availableValues);
    console.log(this.visibleOptions);
    console.log(this.selectedValue);

  }

  onSearch(): void {
    this.visibleOptions = this.availableValues.filter((option: string) =>
      option.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  selectOption(option: any): void {
    this.selectedValue = option;
    this.params.value = this.selectedValue;
    this.params.stopEditing();
  }

  getValue(): any {
    return this.selectedValue;
  }
}
