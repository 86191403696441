import { AfterContentInit, Component } from '@angular/core';
import { CalcInputComponent, SelectOption } from './calc-input/calc-input.component';
import {
  AlimentResponseDTO,
  ProductResponseDTO,
  ClaimResponseDTO,
  StateResponseDTO,
  VarietiesService,
  VarietyResponseDTO,
  SupplierResponseDTO,
  OriginResponseDTO,
  ClientResponseDTO,
} from '@solverml/api';
import { lastValueFrom } from 'rxjs';
import { LabAnalysisService } from 'src/app/services/lab-analysis.service';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CalcTextInputComponent } from './calc-text-input/calc-text-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-calculadora-nombres',
  templateUrl: './calculadora-nombres.component.html',
  styleUrls: ['./calculadora-nombres.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CalcInputComponent,
    CalcTextInputComponent,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
})
export class CalculadoraNombresComponent implements AfterContentInit {
  aliments: AlimentResponseDTO[] = [];
  filteredAliments: SelectOption[] = [];

  products: ProductResponseDTO[] = [];
  filteredProducts: SelectOption[] = [];

  claims: ClaimResponseDTO[] = [];
  filteredClaims: SelectOption[] = [];

  states: StateResponseDTO[] = [];
  filteredStates: SelectOption[] = [];

  varieties: VarietyResponseDTO[] = [];
  filteredVarieties: SelectOption[] = [];

  suppliers: SupplierResponseDTO[] = [];
  filteredSuppliers: SelectOption[] = [];

  origins: OriginResponseDTO[] = [];
  filteredOrigins: SelectOption[] = [];

  clients: ClientResponseDTO[] = [];
  filteredClients: SelectOption[] = [];

  batchValue: string = '';

  selectedItems: {
    [key: string]: string;
  } = {};

  isTextCopied: boolean = false;

  editedCode: string = '';
  codeOnClipboard: string | null = null;

  isInitialDataLoaded: boolean = false;
  isEditModeEnabled: boolean = false;

  constructor(
    private readonly labAnalysisService: LabAnalysisService,
    private readonly varietiesService: VarietiesService
  ) {
    this.clipboardDaemon();
  }

  async ngAfterContentInit(): Promise<void> {
    await this.loadInitialData();
    await this.preselectItems();
    this.editedCode = this.getGeneratedCode();
  }

  onSelect(selector: string, value: string) {
    this.selectedItems[selector] = value;

    if (selector === 'aliment') {
      this.filteredVarieties = this.varieties
        .filter((variety) => variety.aliment.id === value)
        .map((variety) => ({
          value: variety.id,
          viewValue: variety.name,
        }));
      this.selectedItems['variety'] = this.filteredVarieties[0]?.value;
    }

    this.editedCode = this.getGeneratedCode();
  }

  onBatchChange($event: string) {
    this.batchValue = $event;

    this.editedCode = this.getGeneratedCode();
  }

  onCodeChange($event: Event) {
    const value = ($event.target as HTMLInputElement).value;
    this.editedCode = value;
    this.decodeCode(value);
  }

  async copyCodeToClipboard() {
    const code = this.editedCode;
    await navigator.clipboard.writeText(code);
    this.codeOnClipboard = code;
    this.isTextCopied = true;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    this.isTextCopied = false;
  }

  setEditMode(value: boolean) {
    this.isEditModeEnabled = value;
  }

  onPasteFromClipboard() {
    this.decodeCode(this.codeOnClipboard || '');
    this.codeOnClipboard = null;
  }

  private async preselectItems() {
    this.filteredAliments = this.aliments.map((aliment) => ({
      value: aliment.id,
      viewValue: aliment.name,
    }));

    this.filteredProducts = this.products.map((product) => ({
      value: product.id,
      viewValue: product.name,
    }));

    this.filteredClaims = this.claims.map((claim) => ({
      value: claim.id,
      viewValue: claim.name,
    }));

    this.filteredStates = this.states.map((state) => ({
      value: state.id,
      viewValue: state.name,
    }));

    this.filteredSuppliers = this.suppliers.map((supplier) => ({
      value: supplier.id,
      viewValue: supplier.description,
    }));

    this.filteredOrigins = this.origins.map((origin) => ({
      value: origin.id,
      viewValue: origin.country,
    }));

    this.filteredClients = this.clients.map((client) => ({
      value: client.id,
      viewValue: client.description,
    }));

    this.selectedItems = {
      aliment: null,
      product: null,
      claim: null,
      state: null,
      variety: null,
      supplier: null,
      origin: null,
      client: null,
    };

    // Force the variety to be selected through the aliment
    this.onSelect('aliment', this.selectedItems['aliment']);
  }

  private async loadInitialData() {
    const aliments = this.labAnalysisService.getAliments();

    const products = this.labAnalysisService.getProducts();

    const claims = this.labAnalysisService.getClaims();

    const states = this.labAnalysisService.getStates();

    const suppliers = this.labAnalysisService.getSuppliers();

    const origins = this.labAnalysisService.getOrigins();

    const clients = this.labAnalysisService.getClients();

    const varieties = lastValueFrom(
      this.varietiesService.varietiesGetAllVarieties()
    );

    const [
      alimentsResponse,
      productsResponse,
      claimsResponse,
      statesResponse,
      varietiesResponse,
      suppliersResponse,
      originsResponse,
      clientsResponse,
    ] = await Promise.all([
      aliments,
      products,
      claims,
      states,
      varieties,
      suppliers,
      origins,
      clients,
    ]);

    this.aliments = alimentsResponse.filter(
      (aliment) => !aliment.id.toLowerCase().includes('other')
    );
    this.products = productsResponse.filter(
      (product) => !product.id.toLowerCase().includes('other')
    );
    this.claims = claimsResponse.filter(
      (claim) => !claim.id.toLowerCase().includes('other')
    );
    this.states = statesResponse.filter(
      (state) => !state.id.toLowerCase().includes('other')
    );
    this.varieties = varietiesResponse.filter(
      (variety) => !variety.id.toLowerCase().includes('other')
    );
    this.suppliers = suppliersResponse.filter(
      (supplier) => !supplier.id.toLowerCase().includes('other')
    );
    this.origins = originsResponse.filter(
      (origin) => !origin.id.toLowerCase().includes('other')
    );
    this.clients = clientsResponse.filter(
      (client) => !client.id.toLowerCase().includes('other')
    );

    this.isInitialDataLoaded = true;
  }

  private getGeneratedCode() {
    const values = [
      this.selectedItems['aliment'] || '#',
      this.selectedItems['product'] || '#',
      this.selectedItems['claim'] || '#',
      this.selectedItems['state'] || '#',
      this.selectedItems['variety'] || '#',
      this.selectedItems['supplier'] || '#',
      this.selectedItems['origin'] || '#',
      this.batchValue || '#',
      this.selectedItems['client'] || '#',
    ];

    const displayedText = values.join('-');

    return displayedText;
  }

  private decodeCode(code: string) {
    const values = code.split('-');

    const aliment = values[0] || '#';
    const product = values[1] || '#';
    const claim = values[2] || '#';
    const state = values[3] || '#';
    const variety = values[4] || '#';
    const supplier = values[5] || '#';
    const origin = values[6] || '#';
    const batch = values[7] || '#';
    const client = values[8] || '#';

    if (batch.trim() === '#') {
      this.batchValue = null;
    } else {
      this.batchValue = batch;
    }

    this.onSelect('aliment', aliment);
    this.onSelect('product', product);
    this.onSelect('claim', claim);
    this.onSelect('state', state);
    this.onSelect('variety', variety);
    this.onSelect('supplier', supplier);
    this.onSelect('origin', origin);
    this.onSelect('client', client);

    this.onBatchChange(this.batchValue);

    this.editedCode = this.getGeneratedCode();
  }

  private canDecodeCode(code: string) {
    return code.split('-')?.length === 9;
  }

  private clipboardDaemon() {
    const interval = setInterval(async () => {
      try {
        const clipboardData = await navigator.clipboard.readText();
        if (
          this.canDecodeCode(clipboardData) &&
          this.editedCode !== clipboardData &&
          this.codeOnClipboard !== clipboardData
        ) {
          this.codeOnClipboard = clipboardData;
        }
      } catch (error: DOMException | any | unknown) {
        // If the clipboard access is denied, stop the interval
        if (error instanceof DOMException && document.hasFocus()) {
          console.error('Clipboard access denied', error);
          clearInterval(interval);
        }
      }
    }, 1000);
  }
}
