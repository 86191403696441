import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  NotificationManagerService,
  NotificationSubscriber,
} from 'src/app/services/notification-manager.service';

import { NotificationResponse } from '@solverml/api';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent
  implements OnInit, NotificationSubscriber
{
  @Input() isOpen: boolean = false;
  @Input() isFullScreen: boolean = false;

  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  unreadNotifications: NotificationResponse[] = [];
  readNotifications: NotificationResponse[] = [];
  showReadNotifications: boolean = false;

  constructor(
    private notificationService: NotificationManagerService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    // Subscribe to the notification service
    this.notificationService.subscribe(this);
  }

  ngOnChanges() {
    // If notification center is closed, reset the notifications view
    setTimeout(() => {
      if (!this.isOpen) {
        this.showReadNotifications = false;
      }
    }, 100);
  }

  onNotificationCenterUpdate(): void {
    const notifications = this.notificationService.getNotifications();
    this.unreadNotifications = notifications.filter((n) => !n.read);
    this.readNotifications = notifications.filter((n) => n.read);
  }

  @HostListener('document:click', ['$event.target'])
  clickout(element: any) {
    try {
      // If the click was inside the notification (bell icon in navbar) button do nothing
      if (
        element.id === 'notification_button' ||
        element.offsetParent.id === 'notification_button'
      ) {
        return;
      }
    } catch {
      // Sometimes the offsetParent is null, so if an error is thrown ideally we should not hide the notification center to avoid poping it up again
      // Buttons inside the notification center may not have an offsetParent and could fall into this catch block
      return;
    }

    // If the click was inside the notification center do nothing
    if (this.elementRef.nativeElement.contains(element)) {
      return;
    }

    // If the click was outside the notification center, close it
    this.isOpenChange.emit(false);
  }
}
