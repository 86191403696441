export * from './aliments.service';
import { AlimentsService } from './aliments.service';
export * from './aliments.serviceInterface';
export * from './analyses.service';
import { AnalysesService } from './analyses.service';
export * from './analyses.serviceInterface';
export * from './batches.service';
import { BatchesService } from './batches.service';
export * from './batches.serviceInterface';
export * from './claims.service';
import { ClaimsService } from './claims.service';
export * from './claims.serviceInterface';
export * from './clients.service';
import { ClientsService } from './clients.service';
export * from './clients.serviceInterface';
export * from './dictionary.service';
import { DictionaryService } from './dictionary.service';
export * from './dictionary.serviceInterface';
export * from './excelGenerator.service';
import { ExcelGeneratorService } from './excelGenerator.service';
export * from './excelGenerator.serviceInterface';
export * from './health.service';
import { HealthService } from './health.service';
export * from './health.serviceInterface';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './notifications.serviceInterface';
export * from './origins.service';
import { OriginsService } from './origins.service';
export * from './origins.serviceInterface';
export * from './plants.service';
import { PlantsService } from './plants.service';
export * from './plants.serviceInterface';
export * from './products.service';
import { ProductsService } from './products.service';
export * from './products.serviceInterface';
export * from './states.service';
import { StatesService } from './states.service';
export * from './states.serviceInterface';
export * from './suppliers.service';
import { SuppliersService } from './suppliers.service';
export * from './suppliers.serviceInterface';
export * from './utils.service';
import { UtilsService } from './utils.service';
export * from './utils.serviceInterface';
export * from './varieties.service';
import { VarietiesService } from './varieties.service';
export * from './varieties.serviceInterface';
export * from './websocket.service';
import { WebsocketService } from './websocket.service';
export * from './websocket.serviceInterface';
export const APIS = [AlimentsService, AnalysesService, BatchesService, ClaimsService, ClientsService, DictionaryService, ExcelGeneratorService, HealthService, NotificationsService, OriginsService, PlantsService, ProductsService, StatesService, SuppliersService, UtilsService, VarietiesService, WebsocketService];
