import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService, LoginSubscriber } from 'src/app/services/auth.service';
import {
  NotificationManagerService,
  NotificationSubscriber,
} from 'src/app/services/notification-manager.service';
import { WebsocketManagerService } from 'src/app/services/websocket-manager.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent
  implements OnInit, NotificationSubscriber, LoginSubscriber
{
  @Input() sidenav!: MatSidenav;
  @Output() bellIconClick = new EventEmitter<any>();

  hasNotifications: boolean = false;

  user: string = '';
  userProfilePicture: string | ArrayBuffer | null | undefined;

  constructor(
    private notificationManagerService: NotificationManagerService,
    private authService: AuthService,
    private webSocketManagerService: WebsocketManagerService
  ) {}

  /**
   * Método donde se carga los datos del usuario y su nombre para poder mostrarlo en la vista.
   */
  ngOnInit(): void {
    this.user = '';

    this.notificationManagerService.subscribe(this);
    this.authService.subscribe(this);
  }

  onLoginSuccess(): void {
    this.user = this.authService.getAccount()?.name || '';
    const interval = setInterval(async () => {
      try {
        this.userProfilePicture = await this.authService.getProfilePicture();
      } catch (error) {
        console.log('There is no profile picture for the user');
        this.userProfilePicture = null;
      }

      // If undefined, keep trying, if null,  user has no profile picture or not logged in
      if (this.userProfilePicture || this.userProfilePicture === null) {
        clearInterval(interval);
      }
    });
  }

  onLogout(): void {
    this.user = '';
  }

  /**
   * Método encargado de limpiar los datos del usuario y redirigirte a la página de login.
   */
  logout(): void {
    this.authService.logout();
  }

  onNotificationIconClick() {
    this.bellIconClick.emit();
  }

  onNotificationCenterUpdate(): void {
    // setTimeout(() => {
    this.hasNotifications = this.notificationManagerService
      .getNotifications()
      .some((n) => !n.read);
    // });
  }

  isWebSocketConnected(): boolean {
    return this.webSocketManagerService.isConnected();
  }
}
