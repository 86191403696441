import {
  AnalysisResponseDTO,
  AnalysisResultResponseDTO,
} from '.clients/api-client/dist';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, NewValueParams } from 'ag-grid-community';

interface TableRow {
  key: string;
  value: string;
  measurementUnit: string;
}

@Component({
  selector: 'app-analysis-result-table',
  templateUrl: './analysis-result-table.component.html',
  styleUrls: ['./analysis-result-table.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, MatTabsModule, AgGridAngular],
})
export class AnalysisResultTableComponent implements OnInit {
  @Input() set analysisType(value: string) {
    this._analysisType = value;
    this.ngOnInit();
  }

  @Input() set analysis(value: AnalysisResponseDTO) {
    this._analysis = value;
    this.ngOnInit();
  }

  @Input() allowEdit: boolean = false;

  @Output() analysisChange: EventEmitter<void> = new EventEmitter<void>();

  _analysisType: string = '';
  _analysis: AnalysisResponseDTO | null = null;

  rows: TableRow[] = [];
  columnDefs: ColDef[] = [];

  constructor() {}

  ngOnInit() {
    this.rows = [];
    const analysisResults: AnalysisResultResponseDTO[] =
      this._analysis?.results.filter(
        (result) => result.analysis_type === this._analysisType
      );

    if (!analysisResults) {
      return;
    }

    this.rows = analysisResults.map((result) => ({
      key: result.element,
      value: result.value,
      measurementUnit: result.measurement_unit,
    }));

    this.columnDefs = [
      {
        headerName: this.toTitleCase(this._analysisType),
        field: 'key',
        sortable: true,
        filter: true,
        minWidth: 300,
        pinned: 'left',
      },
      {
        headerName: 'Value',
        field: 'value',
        sortable: true,
        filter: true,
        minWidth: 300,
        flex: 1,
        editable: this.allowEdit,
        onCellValueChanged: (event) => {
          this.onCellValueChanged(event);
        },
      },
      {
        headerName: 'Measurement Unit',
        field: 'measurementUnit',
        sortable: true,
        filter: true,
        minWidth: 100,
      },
    ];
  }

  toTitleCase(str: string): string {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  private onCellValueChanged(event: NewValueParams<any, any>): void {
    const key = event.data.key;
    const value = event.newValue;

    const result: AnalysisResultResponseDTO | undefined =
      this._analysis?.results.find((result) => result.element === key);

    if (result) {
      result.value = value;
    }

    this.analysisChange.emit();
  }
}
