<div *ngIf="isLoading" class="loading-spinner">
  <mat-spinner></mat-spinner>
</div>

<div
  [ngClass]="{
    'hidden': isLoading
}"
>
  <table mat-table [dataSource]="dataSource">
    <!-- Define column headers -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Column definitions -->
    <ng-container
      *ngFor="let column of _columns"
      [matColumnDef]="column.columnId"
    >
      <th mat-header-cell *matHeaderCellDef>{{ column.columnName }}</th>
      <td mat-cell *matCellDef="let element">{{ element[column.columnId] }}</td>
    </ng-container>

    <!-- Actions columns definition -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="action-buttons">
          <div class="action-button edit-button">
            <button
              mat-icon-button
              color="accent"
              (click)="onEditElement(element)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <div class="action-button delete-button">
            <button
              mat-icon-button
              color="warn"
              (click)="onDeleteElement(element)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </td>
    </ng-container>
  </table>
  <!-- Paginator -->
  <mat-paginator [pageSizeOptions]="[5, 15, 25, 50, 100]" showFirstLastButtons>
  </mat-paginator>

  <div class="add-button-container">
    <button mat-fab color="primary" (click)="onCreateElement()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>