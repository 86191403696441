<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>ID</mat-label>
    <input
      matInput
      [(ngModel)]="data.data.id"
      placeholder="ID"
      [disabled]="data.isUpdate || data.isDelete"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input
      matInput
      [(ngModel)]="data.data.name"
      placeholder="Name"
      [disabled]="data.isDelete"
    />
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!data.isDelete">
  <button mat-button color="warn" (click)="onCancel()">Cancel</button>
  <span [matTooltip]="getButtonTooltip()">
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="data"
      [disabled]="isDisabled()"
      cdkFocusInitial
    >
      Save
    </button>
  </span>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="data.isDelete">
  <button mat-button color="primary" (click)="onCancel()">Cancel</button>
  <button
    mat-flat-button
    color="warn"
    [mat-dialog-close]="data"
    cdkFocusInitial
  >
    Delete permanently
  </button>
</mat-dialog-actions>
