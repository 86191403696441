<section>
  <div class="page-title">
    <h1 class="title">Results</h1>
  </div>

  <div>
    <div class="document-filter-container">
      <app-analysis-filter
        (filterChange)="onFilterChange($event)"
      ></app-analysis-filter>
    </div>
    <div *ngIf="!areAnalysesLoaded" class="load-spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div class="load-spinner-overlay" *ngIf="isDeletingAnalysis">
      <mat-spinner></mat-spinner>
    </div>
    <div class="plant-list-container" *ngIf="areAnalysesLoaded">
      <div class="download-button-container">
        <button
          mat-button
          aria-label="Download"
          color="accent"
          [disabled]="!hasAnyAnalysisFiltered()"
          (click)="onDownloadClick()"
        >
          Download all
          <mat-icon>download</mat-icon>
        </button>
      </div>
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let plant of plants"
          hideToggle="true"
          expanded="false"
          (opened)="onPanelOpen(plant.id)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="plant-title">
                <span class="plant-id">
                  {{ plant.id }}
                </span>
                <span class="plant-address"> {{ plant.address }}. </span>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <div *ngIf="analysisCountPerPlant[plant.id] > 0">
                {{ analyses[plant.id].length }} of
                {{ analysisCountPerPlant[plant.id] }}
                analyses
              </div>
              <div *ngIf="analysisCountPerPlant[plant.id] === 0">
                No analyses available
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-analysis-result-list
            *ngIf="analyses[plant.id].length > 0 && openedPanelId === plant.id"
            [analyses]="analyses[plant.id]"
            (deleteAnalysis)="deleteAnalysis($event, plant.id)"
          ></app-analysis-result-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</section>
