import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { map, Observable, startWith } from 'rxjs';

export interface SelectOption {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-calc-input',
  templateUrl: './calc-input.component.html',
  styleUrls: ['./calc-input.component.css'],
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatIconModule, MatInputModule, MatSelectModule],
})
export class CalcInputComponent {
  @Input() label: string = '';
  @Input() options: SelectOption[] = [];
  @Input() selectedOption: string = '';
  @Input() isDisabled: boolean = false;
  @Output() selectedOptionChange = new EventEmitter<string>();

  constructor() { }

  onSelectedOptionChange() {
    this.selectedOptionChange.emit(this.selectedOption);
  }

  onValueChange(option: string) {
    this.selectedOptionChange.emit(option);
  }
}
