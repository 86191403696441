<!-- Este será nuestro componente donde tendremos el resto de elementos -->
<mat-toolbar
  class="mat-elevation-z8"
  [ngClass]="{ glow: !isWebSocketConnected() }"
>
  <mat-toolbar-row>
    <!-- Aquí tendremos el botón para abrir el sidebar (si no está abierto) -->
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    </button>

    <!-- Tenemos el nombre del proyecto centrado -->
    <div class="d-flex justify-content-center">
      <span *ngIf="isWebSocketConnected()"> DataLab AI </span>
      <div *ngIf="!isWebSocketConnected()" class="reconnect">
        <div class="icon-container">
          <mat-icon class="icon rotating">autorenew</mat-icon>
        </div>
        <div class="text">Connection lost, please wait...</div>
      </div>
    </div>

    <!-- Aquí tenemos un div que nos permitirá añadir a la derecha del todo el botón
      de notificaciones y del usuario -->
    <div class="d-flex">
      <div [ngClass]="{ 'notification-dot': hasNotifications }"></div>
      <button
        id="notification_button"
        mat-icon-button
        style="margin: auto; font-size: large"
        (click)="onNotificationIconClick()"
      >
        <div>
          <mat-icon
            [ngClass]="{ bell: hasNotifications }"
            [fontSet]="
              hasNotifications ? 'material-icons' : 'material-icons-outlined'
            "
          >
            notifications
          </mat-icon>
        </div>
      </button>

      <!-- El botón de usuario tiene un desplegable con las diferentes opciones -->
      <div id="user_nav_profile">
        <button
          mat-button
          class="user_nav_profile_button"
          [matMenuTriggerFor]="menu"
        >
          <div class="username_button">
            <img
              *ngIf="!userProfilePicture"
              class="avatar default-avatar"
              src="assets/static/images/user.png"
              alt="avatar"
            />
            <img
              *ngIf="userProfilePicture"
              class="avatar"
              [src]="userProfilePicture"
              alt="avatar"
            />
            &nbsp;
            <div class="username">
              <span>{{ user }}</span>
            </div>
          </div>
        </button>

        <!-- Aquí se define las opciones del desplegable al clicar en el usuario -->
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="/profile">
            <mat-icon>account_circle</mat-icon>Perfil
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>Cerrar sesión
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
