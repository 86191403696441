<div>
  <mat-tab-group>
    <mat-tab [label]="'Aliment Type'">
      <div class="spacing-top">
        <app-manage-aliments></app-manage-aliments>
      </div>
    </mat-tab>
    <mat-tab [label]="'Raw Material/Product'">
      <div class="spacing-top">
        <app-manage-products></app-manage-products>
      </div>
    </mat-tab>
    <mat-tab [label]="'Claim'">
      <div class="spacing-top">
        <app-manage-claims></app-manage-claims>
      </div>
    </mat-tab>
    <mat-tab [label]="'State/Production Line'">
      <div class="spacing-top">
        <app-manage-states></app-manage-states>
      </div>
    </mat-tab>
    <mat-tab [label]="'Variety'">
      <div class="spacing-top">
        <app-manage-varieties></app-manage-varieties>
      </div>
    </mat-tab>
    <mat-tab [label]="'Suplier'">
      <div class="spacing-top">
        <app-manage-suppliers></app-manage-suppliers>
      </div>
    </mat-tab>
    <mat-tab [label]="'Origin country'">
      <div class="spacing-top">
        <app-manage-origins></app-manage-origins>
      </div>
    </mat-tab>
    <mat-tab [label]="'Client'">
      <div class="spacing-top">
        <app-manage-clients></app-manage-clients>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
