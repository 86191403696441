<div class="mat-elevation-z4 mt-3">

  <div class="table-container">

    <!-- Tabla con los datos -->
    <table mat-table [dataSource]="tableDataSource" class="demo-table">

      <ng-container
        matColumnDef="{{ column.column_def }}"
        *ngFor="let column of columnList; index as index"
      >
        <!-- Definición de la fila con los headers de la tabla -->
        <tr>
          <th mat-header-cell *matHeaderCellDef class="{{ column.column_def }}">
            <div
              [ngClass]="{
                'header-filter-visible': showFilters,
                'normal-header': !showFilters
              }"
            >
              {{ column.title }}
            </div>

            <!-- Si no es una de las columnas de acción, ponemos un input con autocomplete -->
            <div
              *ngIf="!actionList.includes(column.column_def)"
              class="text-field"
            >
              <mat-form-field
                style="height: 2rem; width: 10rem"
                *ngIf="showFilters"
                subscriptSizing="fixed"
                appearance="outline"
                floatLabel="auto"
              >
                <input
                  matInput
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete #auto="matAutocomplete">
                </mat-autocomplete>
                <mat-icon matSuffix style="position: relative; top: -0.7rem"
                  >search</mat-icon
                >
              </mat-form-field>
            </div>
          </th>
        </tr>

        <!-- Definición de las filas con todos los datos -->
        <tr>
          <td
            mat-cell
            *matCellDef="let element"
            class="{{ column.column_def }}"
          >
            <!-- No es una columna de acción -->
            <div
              *ngIf="!actionList.includes(column.column_def); else actionColumn"
            >

              <!-- Es una columna de true/false y es true -->
              <span *ngIf="element[column.column_def] === true">
              {{getText("txt_administrar_table_value_is_superuser_yes")}}
              </span>

              <!-- Es una columna de true/false y es false -->
              <span *ngIf="element[column.column_def] === false">
              {{getText("txt_administrar_table_value_is_superuser_no")}}
              </span>

              <!-- Es una columna con un valor (no es true/false) -->
              <span
                *ngIf="this.objectColumns[index]"
                >
                  <span *ngFor="let item of element[column.column_def]" class="table-list-field">
                    {{ item.permission_description }}
                  </span>
              </span>

              <!-- Es una columna con un valor (no es true/false) -->
              <span
                *ngIf="
                  element[column.column_def] !== false &&
                  element[column.column_def] !== true &&
                  !this.objectColumns[index]
                "
                >
                {{ element[column.column_def] }}
              </span>

            </div>

            <!-- Columna de acción -->
            <ng-template #actionColumn>
              <button
                mat-icon-button
                color="primary"
                (click)="actionRow(element, column.column_def)"
              >
                <mat-icon>{{ actionIcons[column.column_def] }}</mat-icon>
              </button>
            </ng-template>

          </td>
        </tr>

      </ng-container>

      <!-- Si es searchable se añade un botón de filtros para poder buscar -->
      <ng-container matColumnDef="select" *ngIf="searchable">
        <th mat-header-cell *matHeaderCellDef>
          <button
            mat-icon-button
            matTooltip="Filtrar"
            (click)="toggleFilters()"
          >
            <mat-icon>filter_list</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef=""></td>
      </ng-container>

      <!-- Si no es searchable se añade elementos estéticos sin ninguna función -->
      <ng-container matColumnDef="select" *ngIf="!searchable">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-icon-button>
            <mat-icon>group</mat-icon>
          </div>
        </th>
        <td mat-cell *matCellDef=""></td>
      </ng-container>

      <!-- Header y filas reales -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'fixed-header-row': isFirstRow(row) }"
      ></tr>

    </table>

  </div>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20, 50]"
    [length]="dataSourceLength"
    [pageSize]="paginatorStartSize"
    showFirstLastButtons
    aria-label="Select page"
    (page)="onPaginateChange($event)"
  >
  </mat-paginator>
</div>

<!-- Boton de añadir datos -->
<div *ngIf="allowAdd" class="add-data-button">
  <button mat-fab (click)="addData()" class="demo-button mt-2" color="primary">
    <mat-icon>add_circle</mat-icon>
  </button>
</div>
