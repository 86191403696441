<section>
  <mat-form-field>
    <input matInput placeholder="Batch..." [matAutocomplete]="auto" (change)="onBatchValueChange($event)" (keyup)="onKeyup($event)">
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete">
    @for (option of autcomplete_options; track option) {
      <mat-option 
      [value]="option"
      (onSelectionChange)="onAutocompleteSelectionChange(option)"
      [innerHTML]="formatOption(option)"
      >
      </mat-option>
    }
  </mat-autocomplete>
</section>
