import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-calc-text-input',
  templateUrl: './calc-text-input.component.html',
  styleUrls: ['./calc-text-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule
  ],
})
export class CalcTextInputComponent {
  @Input() value: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  onValueChange($event: Event) {
    this.value = ($event.target as HTMLInputElement).value;
    console.log(this.value);
    this.valueChange.emit(this.value);
  }
}
