import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PlantResponseDTO, PlantsService } from '@solverml/api';
import { lastValueFrom } from 'rxjs';
import { LabAnalysisService } from 'src/app/services/lab-analysis.service';

@Component({
  selector: 'app-plant-filter',
  templateUrl: './plant-filter.component.html',
  styleUrls: ['./plant-filter.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
  ],
})
export class PlantFilterComponent implements AfterViewInit {
  @Input() set selection(value: PlantResponseDTO[]) {
    this.formControl.setValue(value);
  }

  @Output() selectionChange = new EventEmitter<PlantResponseDTO[]>();

  formControl: FormControl = new FormControl('');
  optionList: PlantResponseDTO[] = [];

  constructor(private readonly labAnalysisService: LabAnalysisService) {}

  async ngAfterViewInit() {
    this.optionList = await this.labAnalysisService.getPlants();

    this.formControl.valueChanges.subscribe(() => {
      this.selectionChange.emit(this.formControl.value);
    });
  }
}
