/**
 * Full Stack FastAPI MARIADB
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Body for updating a notification  Attributes: ---------- id : int         Manadatory id of the notification to be updated user_id: str         Mandatory user id to whom the notification is to be sent read : Optional[bool] = False         Whether the notification has been read or not icon : Optional[str] = \'info\'         Icon to display with the notification has_attachment : Optional[bool] = False         Whether the notification has an attachment
 */
export interface NotificationBodyUpdate { 
    id: number;
    read: boolean;
}

