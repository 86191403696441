import { AfterContentInit, Component, Input } from '@angular/core';
import { NotificationResponse } from '@solverml/api';
import { NotificationManagerService } from 'src/app/services/notification-manager.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements AfterContentInit {
  @Input() notification!: NotificationResponse;
  @Input() showDeleteButton: boolean = false;

  isDownloading = false;

  lazyDateDifference: string | null = null;

  deleteButtonIcon = 'delete_outline';

  constructor(private notificationManagerService: NotificationManagerService) {}

  ngAfterContentInit() {
    setInterval(() => {
      this.lazyDateDifference = this.getLazyDateDiffenceFromNow(
        this.notification.date
      );
    }, 1000);
  }

  onToggleMarkAsRead() {
    this.notificationManagerService.setRead(
      this.notification.id,
      !this.notification.read
    );
  }

  onDelete(){
    this.notificationManagerService.deleteNotification(this.notification.id);
  }

  downloadNotificationAttachment() {
    this.isDownloading = true;
    setTimeout(async () => {
      await this.notificationManagerService.downloadAttachment(
        this.notification.id
      );
      this.isDownloading = false;
    });
  }

  protected setDeleteButtonFill(fill: boolean) {
    const iconBase = 'delete';
    this.deleteButtonIcon = fill ? iconBase : iconBase + '_outline';
  }

  protected getLazyDateDiffenceFromNow(stringDate: string) {
    const date = new Date(stringDate); // DateString from server is already in UTC
    const now = new Date( // Fixing timezone offset to UTC
      new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000 // get timezone offset in minutes, transform to milliseconds
    );

    // Now we have the same timezone for both dates and we can compare them
    const diff = Math.abs(Math.floor(now.getTime() - date.getTime()));

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);
    const decades = Math.floor(years / 10);

    const temporalidad = now.getTime() >= date.getTime() ? 'hace' : 'dentro de';
    let result = '';

    if (decades > 0) {
      result = `${temporalidad} ${decades} década${decades > 1 ? 's' : ''}`;
    } else if (years > 0) {
      result = `${temporalidad} ${years} año${years > 1 ? 's' : ''}`;
    } else if (months > 0) {
      result = `${temporalidad} ${months} mes${months > 1 ? 'es' : ''}`;
    } else if (days > 0) {
      result = `${temporalidad} ${days} día${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      result = `${temporalidad} ${hours} hora${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      result = `${temporalidad} ${minutes} minuto${minutes > 1 ? 's' : ''}`;
    } else {
      result = `${temporalidad} ${seconds} segundo${seconds > 1 ? 's' : ''}`;
    }

    return result;
  }
}
