import {
  AnalysesService,
  PendingDocumentResponseDTO,
} from '@solverml/api';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { lastValueFrom } from 'rxjs';
import {
  WebsocketManagerService,
  WebsocketSubscriber,
} from 'src/app/services/websocket-manager.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-pending-documents-list',
  templateUrl: './pending-documents-list.component.html',
  styleUrls: ['./pending-documents-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    MatListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
})
export class PendingDocumentsListComponent
  implements OnInit, WebsocketSubscriber {

  isLoading = true;

  documents: PendingDocumentResponseDTO[] = [];

  constructor(
    private analysesService: AnalysesService,
    private websocketManagerService: WebsocketManagerService
  ) { }

  async onWebsocketUpdate() {
    await this.refreshPendingDocuments();
  }

  async ngOnInit() {

    const { topic } = await lastValueFrom(
      this.analysesService.analysesGetAnalysesWsTopic()
    );

    this.websocketManagerService.subscribe(topic, this);
  }

  async deleteDocument(document_id: number) {
    await lastValueFrom(
      this.analysesService.analysesDeletePendingDocuments(document_id)
    );
  }

  async retryDocument(document_id: number) {
    await lastValueFrom(
      this.analysesService.analysesRetryPendingDocument(document_id)
    );
  }

  async refreshPendingDocuments() {
    this.isLoading = true;
    await new Promise((resolve) => setTimeout(resolve));
    this.documents = await lastValueFrom(
      this.analysesService.analysesGetPendingDocuments()
    );
    this.isLoading = false;
  }

  async downloadDocument(pendingDocument: PendingDocumentResponseDTO) {
    const response = await lastValueFrom(
      this.analysesService.analysesGetAnalysisDocument(
        `storage/analyses/${pendingDocument.filename}`,
        'response', // We want not only the body but also the headers
        false, // We don't want to report the progress
        {
          httpHeaderAccept: 'application/octet-stream',
        } as any // We want the body to be a blob/file
      )
    );

    // Create a file from the response and open it in a new tab
    const url = window.URL.createObjectURL(response.body);
    const a = document.createElement('a');
    a.href = url;
    a.download = pendingDocument.filename + '.pdf';
    a.target = '_blank';
    a.click();
  }
}
