import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export interface DataInput {
  selected: boolean;
  [key: string]: string | number | boolean | Date;
}

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent implements AfterContentInit {
  // Key: columnID, Value: cell value
  @Input() data!: DataInput[];

  // Key: columnID, Value: column name
  @Input() columns: {
    [key: string]: string;
  } = {};

  @Input() allowSearch: boolean = true;
  @Input() allowEditing: boolean = true;
  @Input() allowRemove: boolean = true;
  @Input() allowSelect: boolean = true;

  @Output() dataChange = new EventEmitter<DataInput[]>();

  searchBoxVisible: boolean = true;

  filter: { [key: string]: string } = {};
  filteredData: DataInput[] = [];
  distinctValues: { [key: string]: Set<string> } = {};

  constructor() {}

  ngAfterContentInit(): void {
    this.updateFilteredData();
  }

  getColumns(): string[] {
    let columns: string[] = Object.keys(this.columns);

    if (columns.length === 0 && this.data.length > 0) {
      columns = Object.keys(this.data[0]);
    }

    return columns;
  }

  getColumnName(column: string): string {
    const columnObj = this.columns[column];
    return columnObj || column;
  }

  isSelected(row: DataInput): boolean {
    return row.selected;
  }

  setSelected(row: DataInput, value: boolean): void {
    row.selected = value;
    this.onDataChange();
  }

  onDataChange() {
    this.dataChange.emit(this.data);
  }

  onSearch(column: string, value: string) {
    this.filter[column] = value;
    this.updateFilteredData();
  }

  updateFilteredData(): void {
    // Clear the filtered data without losing the reference
    this.filteredData.splice(0, this.filteredData.length);

    // Add the filtered data
    this.filteredData.push(
      // filter the original data
      ...this.data.filter((row) => { // Filter each row
        // Check if all the filter conditions are met for a given row for all columns
        return Object.keys(this.filter).every((column) => { // Check each column
          // Check if the column value includes the filter value
          return row[column]
            .toString()
            .toLowerCase()
            .includes(this.filter[column].toString().toLowerCase());
        });
      })
    );

    this.updateDistinctValues();
  }

  private updateDistinctValues(): void {

    // Clear the distinct values without losing the reference
    Object.keys(this.distinctValues).forEach((key) => {
      this.distinctValues[key].clear();
    });

    this.filteredData.forEach((row) => {
      Object.keys(row).forEach((column) => {
        if (!this.distinctValues[column]) {
          this.distinctValues[column] = new Set<string>();
        }
        this.distinctValues[column].add(row[column].toString());
      });
    });
  }
}
