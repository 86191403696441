import { NgIf, NgFor, NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';

export interface Column {
  columnId: string;
  columnName: string;
}

@Component({
  selector: 'app-manage-table',
  templateUrl: './manage-table.component.html',
  styleUrls: ['./manage-table.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    MatTableModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
})
export class ManageTableComponent<T> implements AfterViewInit {
  @Input() set columns(input_columns: Column[]) {
    this._columns.length = 0;
    this._columns.push(...input_columns);
    this.displayedColumns = input_columns.map((column) => column.columnId);
    this.displayedColumns.push('actions'); // Add right action buttons column
  }

  @Input() set data(input_data: T[]) {
    this.dataSource.data = input_data;
  }

  @Input() isLoading: boolean = false;

  @Output() onCreate: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEdit: EventEmitter<T> = new EventEmitter<T>();
  @Output() onDelete: EventEmitter<T> = new EventEmitter<T>();

  @ViewChild(MatTable) table: MatTable<T>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
  _columns: Column[] = [];
  displayedColumns: string[] = [];

  constructor() {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  
  onCreateElement(): void {
    this.onCreate.emit();
  }

  onEditElement(element: T): void {
    this.onEdit.emit(element);
  }
  onDeleteElement(element: T): void {
    this.onDelete.emit(element);
  }
  

}
