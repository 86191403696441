import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-enough-permissions',
  templateUrl: './not-enough-permissions.component.html',
  standalone: true,
  imports: [
    CommonModule
  ],
})
export class NotEnoughPermissionsComponent {

  constructor() { }
}
