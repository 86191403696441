import { Injectable } from '@angular/core';
import {
  AlimentsService,
  ProductsService,
  StatesService,
  PlantsService,
  AlimentResponseDTO,
  ProductResponseDTO,
  StateResponseDTO,
  PlantResponseDTO,
  AnalysesService,
  ClaimsService,
  ClaimResponseDTO,
  ClientsService,
  ClientResponseDTO,
  OriginResponseDTO,
  OriginsService,
  SuppliersService,
  SupplierResponseDTO,
  VarietiesService,
  VarietyResponseDTO,
  AnalysisPerPlantResponseDTO,
  AnalysisBodyFilter,
  MinimalAnalysisResponseDTO,
  AnalysisResponseDTO,
} from '@solverml/api';
import { lastValueFrom } from 'rxjs';
import {
  WebsocketManagerService,
  WebsocketMessage,
  WebsocketSubscriber,
} from './websocket-manager.service';

const MAX_CACHE_SIZE = 10;

@Injectable({
  providedIn: 'root',
})
export class LabAnalysisService implements WebsocketSubscriber {
  private static analysisCountPerPlant: AnalysisPerPlantResponseDTO[] = [];
  private static analaysis: { [key: string]: MinimalAnalysisResponseDTO[] } =
    {};
  private static analysisTypes: string[] = [];
  private static analysisTypesLoaded = false;

  private static aliments: AlimentResponseDTO[] = [];
  private static alimentsLoaded = false;

  private static claims: ClaimResponseDTO[] = [];
  private static claimsLoaded = false;

  private static clients: ClientResponseDTO[] = [];
  private static clientsLoaded = false;

  private static origins: OriginResponseDTO[] = [];
  private static originsLoaded = false;

  private static products: ProductResponseDTO[] = [];
  private static productsLoaded = false;

  private static states: StateResponseDTO[] = [];
  private static statesLoaded = false;

  private static plants: PlantResponseDTO[] = [];
  private static plantsLoaded = false;

  private static suppliers: SupplierResponseDTO[] = [];
  private static suppliersLoaded = false;

  private static varieties: { [key: string]: VarietyResponseDTO[] } = {};

  constructor(
    private analysesService: AnalysesService,
    private alimentsService: AlimentsService,
    private claimsService: ClaimsService,
    private clientsService: ClientsService,
    private originsService: OriginsService,
    private productsService: ProductsService,
    private statesService: StatesService,
    private plantsService: PlantsService,
    private suppliersService: SuppliersService,
    private varietiesService: VarietiesService,
    private websocketManagerService: WebsocketManagerService
  ) {
    setTimeout(async () => {
      const { topic } = await lastValueFrom(
        this.analysesService.analysesGetAnalysesWsTopic()
      );
      this.websocketManagerService.subscribe(topic, this);
    });
  }

  async onWebsocketUpdate(): Promise<void> {
    const previousAnalyses = LabAnalysisService.analaysis;

    LabAnalysisService.analysisCountPerPlant = [];
    LabAnalysisService.analaysis = {};
    LabAnalysisService.analysisTypes = [];
    LabAnalysisService.analysisTypesLoaded = false;

    // Download the new data
    this.getAnalysisCountPerPlant();
    this.getAnalysisTypes();

    // Update the cached analyses
    const promises: Promise<MinimalAnalysisResponseDTO[]>[] = [];
    for (const key in previousAnalyses) {
      const analysisBodyFilter = JSON.parse(key) as AnalysisBodyFilter;
      promises.push(this.getAnalyses(analysisBodyFilter));
    }
    await Promise.all(promises);
  }

  public async getAnalysis(analysisId: string): Promise<AnalysisResponseDTO> {
    return await lastValueFrom(
      this.analysesService.analysesGetAnalysis(analysisId)
    );
  }

  public async getAnalyses(
    analysisBodyFilter: AnalysisBodyFilter
  ): Promise<MinimalAnalysisResponseDTO[]> {
    const key = JSON.stringify(analysisBodyFilter);

    if (!LabAnalysisService.analaysis[key]) {
      // Remove the oldest cached analysis
      if (Object.keys(LabAnalysisService.analaysis).length > MAX_CACHE_SIZE) {
        delete LabAnalysisService.analaysis[
          Object.keys(LabAnalysisService.analaysis)[0]
        ];
      }

      LabAnalysisService.analaysis[key] = await lastValueFrom(
        this.analysesService.analysesGetAllAnalysisIds(analysisBodyFilter)
      );
    }

    return LabAnalysisService.analaysis[key];
  }

  public async getAnalysisCountPerPlant() {
    if (LabAnalysisService.analysisCountPerPlant.length === 0) {
      const analysesCountPerPlant: AnalysisPerPlantResponseDTO[] =
        await lastValueFrom(
          this.analysesService.analysesGetAnalysisCountPerPlant()
        );
      LabAnalysisService.analysisCountPerPlant = analysesCountPerPlant;
    }

    return LabAnalysisService.analysisCountPerPlant;
  }

  public async getAnalysisTypes(): Promise<string[]> {
    if (!LabAnalysisService.analysisTypesLoaded) {
      let analysisTypes = await lastValueFrom(
        this.analysesService.analysesGetAnalysisTypes()
      );
      analysisTypes = analysisTypes.sort((a, b) => a.localeCompare(b));
      LabAnalysisService.analysisTypes = analysisTypes;
      LabAnalysisService.analysisTypesLoaded = true;
    }

    // Return a copy of the array
    return LabAnalysisService.analysisTypes.slice();
  }

  public isAnalysisTypeLoaded(): boolean {
    return LabAnalysisService.analysisTypesLoaded;
  }

  public async getAliments(): Promise<AlimentResponseDTO[]> {
    if (!LabAnalysisService.alimentsLoaded) {
      let aliments = await lastValueFrom(
        this.alimentsService.alimentsGetAllAliments()
      );
      aliments = aliments.sort((a, b) => {
        if (a.name.toLowerCase().includes('other')) {
          return -1;
        }

        if (b.name.toLowerCase().includes('other')) {
          return 1;
        }

        return a.name.localeCompare(b.name);
      });
      LabAnalysisService.aliments = aliments;
      LabAnalysisService.alimentsLoaded = true;
    }
    return LabAnalysisService.aliments.slice();
  }

  public isAlimentsLoaded(): boolean {
    return LabAnalysisService.alimentsLoaded;
  }

  public async getClaims(): Promise<ClaimResponseDTO[]> {
    if (!LabAnalysisService.claimsLoaded) {
      let claims = await lastValueFrom(this.claimsService.claimsGetAllClaims());
      claims = claims.sort((a, b) => {
        if (a.name.toLowerCase().includes('other')) {
          return -1;
        }

        if (b.name.toLowerCase().includes('other')) {
          return 1;
        }

        return a.name.localeCompare(b.name);
      });
      LabAnalysisService.claims = claims;
      LabAnalysisService.claimsLoaded = true;
    }

    return LabAnalysisService.claims.slice();
  }

  public isClaimsLoaded(): boolean {
    return LabAnalysisService.claimsLoaded;
  }

  public async getClients(): Promise<ClientResponseDTO[]> {
    if (!LabAnalysisService.clientsLoaded) {
      let clients = await lastValueFrom(
        this.clientsService.clientsGetAllClients()
      );
      clients = clients.sort((a, b) =>
        a.description.localeCompare(b.description)
      );
      LabAnalysisService.clients = clients;
      LabAnalysisService.clientsLoaded = true;
    }

    return LabAnalysisService.clients.slice();
  }

  public isClientsLoaded(): boolean {
    return LabAnalysisService.clientsLoaded;
  }

  public async getOrigins(): Promise<OriginResponseDTO[]> {
    if (!LabAnalysisService.originsLoaded) {
      let origins = await lastValueFrom(
        this.originsService.originsGetAllOrigins()
      );
      origins = origins.sort((a, b) => {

        if (a.country.toLowerCase().includes('other')) {
          return -1;
        }

        if (b.country.toLowerCase().includes('other')) {
          return 1;
        }

        return a.country.localeCompare(b.country);
      });
      LabAnalysisService.origins = origins;
      LabAnalysisService.originsLoaded = true;
    }
    return LabAnalysisService.origins.slice();
  }

  public isOriginsLoaded(): boolean {
    return LabAnalysisService.originsLoaded;
  }

  public async getProducts(): Promise<ProductResponseDTO[]> {
    if (!LabAnalysisService.productsLoaded) {
      let products = await lastValueFrom(
        this.productsService.productsGetAllProducts()
      );
      products = products.sort((a, b) => {
        if (a.name.toLowerCase().includes('other')) {
          return 1;
        }

        if (b.name.toLowerCase().includes('other')) {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });
      LabAnalysisService.products = products;
      LabAnalysisService.productsLoaded = true;
    }
    return LabAnalysisService.products.slice();
  }

  public isProductsLoaded(): boolean {
    return LabAnalysisService.productsLoaded;
  }

  public async getStates(): Promise<StateResponseDTO[]> {
    if (!LabAnalysisService.statesLoaded) {
      let states = await lastValueFrom(this.statesService.statesGetAllStates());
      states = states.sort((a, b) => {
        if (a.name.toLowerCase().includes('other')) {
          return -1;
        }

        if (b.name.toLowerCase().includes('other')) {
          return 1;
        }

        return a.name.localeCompare(b.name);
      });
      LabAnalysisService.states = states;
      LabAnalysisService.statesLoaded = true;
    }
    return LabAnalysisService.states.slice();
  }

  public isStatesLoaded(): boolean {
    return LabAnalysisService.statesLoaded;
  }

  public async getPlants(): Promise<PlantResponseDTO[]> {
    if (!LabAnalysisService.plantsLoaded) {
      let plants = await lastValueFrom(this.plantsService.plantsGetAllPlants());
      plants = plants.sort((a: PlantResponseDTO, b: PlantResponseDTO) => {
        let comparison: number = a.id.localeCompare(b.id);

        if (a.id.toLowerCase().includes('other')) {
          comparison = 1;
        }

        if (b.id.toLowerCase().includes('other')) {
          comparison = -1;
        }

        return comparison;
      });

      LabAnalysisService.plants = plants;
      LabAnalysisService.plantsLoaded = true;
    }
    return LabAnalysisService.plants.slice();
  }

  public isPlantsLoaded(): boolean {
    return LabAnalysisService.plantsLoaded;
  }

  public async getSuppliers(): Promise<SupplierResponseDTO[]> {
    if (!LabAnalysisService.suppliersLoaded) {
      let suppliers = await lastValueFrom(
        this.suppliersService.suppliersGetAllSuppliers()
      );
      suppliers = suppliers.sort((a, b) => {
        if (a.description.toLowerCase().includes('other')) {
          return -1;
        }

        if (b.description.toLowerCase().includes('other')) {
          return 1;
        }

        return a.description.localeCompare(b.description);
      });
      LabAnalysisService.suppliers = suppliers;
      LabAnalysisService.suppliersLoaded = true;
    }
    return LabAnalysisService.suppliers.slice();
  }

  public isSuppliersLoaded(): boolean {
    return LabAnalysisService.suppliersLoaded;
  }

  public async getVarieties(
    alimentId: string | undefined | null
  ): Promise<VarietyResponseDTO[]> {
    if (!alimentId) {
      return [];
    }

    if (!LabAnalysisService.varieties[alimentId]) {
      LabAnalysisService.varieties[alimentId] = await lastValueFrom(
        this.varietiesService.varietiesGetAllVarieties(alimentId)
      );

      LabAnalysisService.varieties[alimentId] = LabAnalysisService.varieties[
        alimentId
      ].sort((a, b) => {
        if (a.name?.toLowerCase().includes('other')) {
          return 1;
        }

        if (b.name?.toLowerCase().includes('other')) {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });
    }

    return LabAnalysisService.varieties[alimentId].slice();
  }
}
