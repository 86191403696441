<section>
  <mat-form-field>
    <input
      matInput
      placeholder="Batch..."
      [matAutocomplete]="auto"
      [formControl]="formControl"
      (change)="onBatchValueChange($event)"
      (keyup)="onKeyup($event)"
    />
  </mat-form-field>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAutocompleteSelectionChange($event)">
    @for (option of autcomplete_options; track option) {
    <mat-option
      [value]="option"
      [innerHTML]="formatOption(option)"
    >
    </mat-option>
    }
  </mat-autocomplete>
</section>
