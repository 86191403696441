<section>
  <mat-form-field>
    <mat-label>States/Production lines</mat-label>
    <mat-select [formControl]="formControl" multiple>
      <mat-option *ngFor="let option of optionList" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  
</section>