import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatNativeDateModule,
} from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AppDateAdapter, APP_DATE_FORMATS } from './date-formatter';

export class DateRange {
  start: Date;
  end: Date;
}

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, MatDatepickerModule, MatNativeDateModule],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class DateFilterComponent {
  @Input() set dateRange(value: DateRange) {
    this.startDate = value.start;
    this.endDate = value.end;
  }

  @Output() dateChange = new EventEmitter<DateRange>();

  startDate: Date;
  endDate: Date;

  constructor() {}

  onStartDateChange(event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.notifyDateChange();
  }

  onEndDateChange(event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.notifyDateChange();
  }

  notifyDateChange() {
    this.dateChange.emit({ start: this.startDate, end: this.endDate });
  }
}
