<div class="login-container">
  <!-- Bloque central con el login -->
  <mat-card>
    <mat-card-content class="mat-elevation-z8">
      <!-- Icono con el logo de la empresa -->
      <img
        id="logo_sidebar"
        class="avatar"
        src="assets/static/images/logo-dacsa.png"
      />
      <div class="login-button">
        <button mat-fab extended (click)="login()">
          <mat-icon>login</mat-icon>
          Login with DACSA
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
