import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-drag-drop-files',
  templateUrl: './drag-drop-files.component.html',
  styleUrls: ['./drag-drop-files.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
})
export class DragDropFilesComponent implements OnInit {
  constructor() {}
  @Input('label') label?: string;
  @Input('allowedExtensions') allowedExtensions: string[] = [];
  @Input('allowMultipleFiles') allowMultipleFiles: boolean = false;
  @Input('files') files: File[] = [];

  @Output('onFilesDropped') onFilesDropped: EventEmitter<File[]> =
    new EventEmitter<File[]>();

  isDroping: boolean = false;
  allowedList: string = 'todos';
  incorrectInput: boolean = false;
  errorMessage: string = '';

  ngOnInit() {
    if (this.allowedExtensions.length > 0) {
      this.allowedList = this.allowedExtensions.join(', ');
    }

    window.addEventListener('dragover', (e) => this.onDragOver(e));
    window.addEventListener('dragleave', (e) => this.onDragLeave(e));
    window.addEventListener('drop', (e) => this.onFiledrop(e));
  }

  /**
   * Cuando this.isDroping es true, el HTML muestra el fondo sombreado de la zona de
   * arrastrar y soltar junto con el mensaje de "Suelta el archivo aquí" y el icono
   * de la nube.
   */
  onDragOver($event: DragEvent) {
    $event.preventDefault();
    this.isDroping = true;
  }

  /**
   * Cuando this.isDroping es false, el HTML deja de mostrar el fondo sombreado de la
   * zona de arrastrar y soltar junto con el mensaje de "Suelta el archivo aquí" y el
   * icono de la nube.
   */
  onDragLeave($event: DragEvent) {
    $event.preventDefault();
    this.isDroping = false;
  }

  /**
   * Se encarga de manejar el evento de soltar un archivo en la zona de arrastrar y soltar.
   * Se llama a la función handleDrop con los archivos que se han soltado.
   */
  onFiledrop($event: any) {
    this.onDragLeave($event);
    $event.stopPropagation();
    this.handleDrop($event.dataTransfer.files);
  }

  onFileSelected($event: any) {
    this.handleDrop($event.target.files);
  }

  formatFileNameSize(filename: string): string {
    const max_length = 20;
    return filename.length > max_length
      ? filename.substring(0, max_length) +
          '...' +
          filename.substring(filename.length - max_length)
      : filename;
  }

  formatBytes(x: number | string): string {
    const units = [
      'bytes',
      'KiB',
      'MiB',
      'GiB',
      'TiB',
      'PiB',
      'EiB',
      'ZiB',
      'YiB',
    ];

    let l = 0,
      n = parseInt(x.toString(), 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.onFilesDropped.emit(this.files);
  }

  viewFile(index: number) {
    const file = this.files[index];
    const url = URL.createObjectURL(file);
    window.open(url);
  }

  private validateExtensions(files: FileList): boolean {
    if (this.allowedExtensions.length === 0) {
      return true;
    }
    const extensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;

    let extensions: string[] = [];
    Array.from(files).map((file) =>
      file?.name
        ?.toLowerCase()
        .match(extensionPattern)
        ?.map((extension) => extensions.push(extension))
    );

    const forbidden_extensions = extensions.filter(
      (x) => !this.allowedExtensions.includes(x)
    );
    const valid = forbidden_extensions.length === 0;
    return valid;
  }

  private handleDrop(files: FileList) {
    this.errorMessage = '';
    this.incorrectInput = false;

    const fileInputArray = Array.from(files);

    // Check if multiple files are allowed and if multiple files are dropped
    this.incorrectInput =
      !this.allowMultipleFiles && (files.length > 1 || this.files.length > 0);
    if (this.incorrectInput) {
      this.incorrectInput = true;
      this.errorMessage = 'Solo se permite un archivo a la vez';
      return;
    }

    // Check if the file extension of the dropped files are allowed
    this.incorrectInput = !this.validateExtensions(files);
    if (this.incorrectInput) {
      this.errorMessage =
        'Solo se permiten archivos con extensiones: ' + this.allowedList;
      return;
    }

    //Prevent file duplication by name
    this.files = this.files.filter(
      (file) => !fileInputArray.some((newFile) => newFile.name === file.name)
    );

    this.files.push(...fileInputArray);
    this.onFilesDropped.emit(this.files);
  }
}
