<section>
  <div class="preview-card">
    <div class="doclist">
      <ul>
        <li *ngFor="let analysis of analyses">
          <span
            [ngClass]="{
              selected: analysis.id === minimalSelectedAnalysis?.id
            }"
            (click)="onSelectAnalysis(analysis)"
          >
            {{ analysis.id }}
          </span>
        </li>
      </ul>
    </div>
    <div class="side-view">
      <div class="top-view">
        <div class="spinner-container" *ngIf="isDownloadingSelectedAnalysis">
          <mat-spinner></mat-spinner>
        </div>
        <app-analysis-result-data
          *ngIf="!isDownloadingSelectedAnalysis"
          [analysis]="selectedAnalysis"
          (onAnalysisRemoved)="onAnalysisRemoved(selectedAnalysis)"
        ></app-analysis-result-data>
      </div>
      <div class="bottom-view">
        <app-analisys-document-preview
          *ngIf="!isDownloadingSelectedAnalysis"
          [analysis]="selectedAnalysis"
        ></app-analisys-document-preview>
      </div>
    </div>
  </div>
</section>
