import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { AnalysisPreviewListComponent } from '../../resultados/analysis-result-list/analysis-result-list.component';
import {
  AnalysesService,
  AnalysisResponseDTO,
  MinimalAnalysisResponseDTO,
} from '@solverml/api';
import { lastValueFrom } from 'rxjs';
import {
  WebsocketManagerService,
  WebsocketMessage,
  WebsocketSubscriber,
} from 'src/app/services/websocket-manager.service';

@Component({
  selector: 'app-last-analyses-list',
  templateUrl: './last-analyses-list.component.html',
  styleUrls: ['./last-analyses-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AnalysisPreviewListComponent,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
  ],
})
export class LastAnalysesListComponent implements OnInit, WebsocketSubscriber {
  
  @Output() onPanelOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  analyses: MinimalAnalysisResponseDTO[] = [];

  isPanelOpen = false;
  isDeletingAnalysis = false;
  areAnalysesLoaded = false;

  constructor(
    private readonly analysesService: AnalysesService,
    private websocketManagerService: WebsocketManagerService
  ) {}

  onWebsocketUpdate(): void {
    this.refreshAnalyses();
  }

  async ngOnInit() {
    const { topic } = await lastValueFrom(
      this.analysesService.analysesGetAnalysesWsTopic()
    );

    this.websocketManagerService.subscribe(topic, this);
  }

  async refreshAnalyses() {
    // this.areAnalysesLoaded = false;
    await new Promise((resolve) => setTimeout(resolve, 1)); // Simulate a delay
    const today = new Date();
    const timestamp = today.toISOString().split('T')[0]; // Get the current date in the format 'YYYY-MM-DD'
    const analyses = await lastValueFrom(
      this.analysesService.analysesGetAllAnalysisIds({
        timestamp,
      })
    );
    this.analyses.length = 0;
    this.analyses.push(...analyses);
    this.areAnalysesLoaded = true;
  }

  onPanelOpen() {
    this.isPanelOpen = true;
    this.panelOpenChange();
  }

  onPanelClose() {
    this.isPanelOpen = false;
    this.panelOpenChange();
  }

  async deleteAnalysis(doc: AnalysisResponseDTO | MinimalAnalysisResponseDTO) {
    this.isDeletingAnalysis = true;

    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay

    await lastValueFrom(this.analysesService.analysesDeleteAnalysis(doc.id));

    // Optimistically remove the analysis from the list to avoid requesting the server again
    this.analyses = this.analyses.filter((analysis) => analysis.id !== doc.id);

    this.isDeletingAnalysis = false;
  }

  private panelOpenChange() {
    this.onPanelOpenChange.emit(this.isPanelOpen);
  }
}
