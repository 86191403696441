import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

const DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-calc-calendar-input',
  templateUrl: './calc-calendar-input.component.html',
  styleUrls: ['./calc-calendar-input.component.scss'],
})
export class CalcCalendarInputComponent {
  @Input() date: Date = new Date();

  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor() {}

  onDateChange($event: MatDatepickerInputEvent<Date>) {
    const date: Date = $event.value || new Date();
    this.dateChange.emit(date);
  }

}
