import { AnalysesService, PendingDocumentResponseDTO } from '@solverml/api';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { lastValueFrom } from 'rxjs';
import {
  WebsocketManagerService,
  WebsocketSubscriber,
} from 'src/app/services/websocket-manager.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-pending-documents-list',
  templateUrl: './pending-documents-list.component.html',
  styleUrls: ['./pending-documents-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
})
export class PendingDocumentsListComponent
  implements OnInit, WebsocketSubscriber {
  isLoading = true;

  documents: PendingDocumentResponseDTO[] = [];

  constructor(
    private analysesService: AnalysesService,
    private websocketManagerService: WebsocketManagerService
  ) { }

  async onWebsocketUpdate() {
    await this.refreshPendingDocuments();
  }

  async ngOnInit() {
    const { topic } = await lastValueFrom(
      this.analysesService.analysesGetAnalysesWsTopic()
    );

    this.websocketManagerService.subscribe(topic, this);
  }

  async deleteDocument(document_id: number) {
    await lastValueFrom(
      this.analysesService.analysesDeletePendingDocuments(document_id)
    );
  }

  async retryDocument(document_id: number) {
    await lastValueFrom(
      this.analysesService.analysesRetryPendingDocument(document_id)
    );
  }

  async refreshPendingDocuments() {
    // this.isLoading = true;
    await new Promise((resolve) => setTimeout(resolve));
    this.documents = await lastValueFrom(
      this.analysesService.analysesGetPendingDocuments()
    );

    // Sort the documents by status, then by id
    // Statuses are ordered as follows: 'success', 'processing', 'pending', 'failed'
    this.documents.sort((a, b) => {
      if (a.status === b.status) {
        return a.id - b.id;
      }

      let statusWeights = {
        success: 0,
        processing: 1,
        pending: 2,
        failed: 3,
      };

      return statusWeights[a.status] - statusWeights[b.status];
    });

    this.isLoading = false;
  }

  async downloadDocument(pendingDocument: PendingDocumentResponseDTO) {
    const response = await lastValueFrom(
      this.analysesService.analysesGetAnalysisDocument(
        `storage/analyses/${pendingDocument.filename}`,
        'response', // We want not only the body but also the headers
        false, // We don't want to report the progress
        {
          httpHeaderAccept: 'application/octet-stream',
        } as any // We want the body to be a blob/file
      )
    );

    // Create a file from the response and open it in a new tab

    let downloadFilename = pendingDocument.original_filename || pendingDocument.filename || 'document';
    if (!downloadFilename.endsWith('.pdf')) {
      downloadFilename += '.pdf';
    }

    // The body is the file
    const file: File = new File([response.body as Blob], downloadFilename, {
      type: 'application/pdf',
    });

    const iframe = document.createElement('iframe');
    iframe.src = URL.createObjectURL(file) + '#zoom=auto';
    iframe.title = downloadFilename;
    iframe.style.border = 'none';
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.style.position = 'fixed';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.right = '0';
    iframe.style.bottom = '0';

    const win = window.open(
      iframe.src,
      '_blank',
    );

    iframe.onload = () => {
      URL.revokeObjectURL(iframe.src);
      win.document.title = downloadFilename;
    };

    if (win) {
      win.document.body.appendChild(iframe);
    }
  }


}
