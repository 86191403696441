/**
 * Full Stack FastAPI MARIADB
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Notification response schema  Attributes: ---------- id : int     Notification id title : str     Title of the notification description : str     Description of the notification read : bool     Whether the notification has been read or not icon : str     Icon to display with the notification has_attachment : bool     Whether the notification has an attachment date: datetime     Date the notification was created
 */
export interface NotificationResponse { 
    id: number;
    title: string;
    description: string;
    read: boolean;
    icon: string;
    has_attachment: boolean;
    date: string;
}

