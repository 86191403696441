<section>
  <mat-form-field>
    <mat-label>Varieties</mat-label>
    <mat-select [formControl]="formControl" multiple>
      <mat-option *ngFor="let option of getAllowedVarieties()" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</section>
