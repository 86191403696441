<div>
  <div class="doc-actions" *ngIf="allowEditActions()">
    <div class="doc-buttons">
      <button
        class="doc-button save-changes"
        mat-raised-button
        color="primary"
        [disabled]="!changed || isChanging"
        (click)="onSaveChangesClick()"
      >
        <span *ngIf="!isChanging">Save changes</span>

        <span *ngIf="isChanging">
          <mat-spinner diameter="20"></mat-spinner>
        </span>
      </button>
      <button
        class="doc-button discard-changes"
        mat-button
        [disabled]="!changed || isChanging"
        (click)="onDiscardChangesCLick()"
      >
        Discard changes
      </button>
    </div>
    <div class="doc-buttons-critical">
      <button
        class="doc-button remove"
        mat-raised-button
        color="warn"
        [disabled]="isChanging"
        (click)="onRemoveAnalysisClick()"
      >
        Remove analysis
      </button>
    </div>
  </div>
</div>

<mat-tab-group>
  <mat-tab label="Metadata">
    <app-analysis-metadata-table
      [analysis]="modified_analysis"
      [allowEdit]="allowEditActions()"
      (analysisChange)="onAnalysisChange()"
    ></app-analysis-metadata-table>
  </mat-tab>
  <mat-tab *ngFor="let type of analysisTypes" [label]="toTitleCase(type)">
    <app-analysis-result-table
      [analysisType]="type"
      [analysis]="modified_analysis"
      [allowEdit]="allowEditActions()"
      (analysisChange)="onAnalysisChange()"
    ></app-analysis-result-table>
  </mat-tab>
</mat-tab-group>
