<table>
  <thead>
    <tr>
      <th class="thin-cell">
        <div
          *ngIf="allowSearch"
          class="search-icon"
          (click)="searchBoxVisible = !searchBoxVisible"
        >
          <mat-icon>search</mat-icon>
        </div>
      </th>
      <th *ngFor="let columnId of getColumns()">
        {{ getColumnName(getColumnName(columnId)) }}
      </th>
    </tr>
    <tr *ngIf="searchBoxVisible" class="search-header">
      <th class="thin-cell"></th>
      <th *ngFor="let columnId of getColumns()">
        <app-simple-table-search-bar [options]="distinctValues[columnId]" (onSearch)="onSearch(columnId, $event)"></app-simple-table-search-bar>
      </th>
    </tr>
  </thead>
  <tbody *ngIf="filteredData.length > 0">
    <tr *ngFor="let row of filteredData">
      <td class="thin-cell">
        <span *ngIf="allowSelect">
          <mat-checkbox
            [checked]="isSelected(row)"
            (change)="setSelected(row, !row.selected)"
          ></mat-checkbox>
        </span>
      </td>
      <td *ngFor="let columnId of getColumns()">
        {{ row[columnId] }}
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="!filteredData.length">
    <tr>
      <td [attr.colspan]="getColumns().length + 1">
        <div class="no-data">
          <mat-icon>info</mat-icon>
          <span>No data available</span>
        </div>
      </td>
    </tr>
</table>
