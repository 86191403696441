<div class="load-spinner" *ngIf="!isInitialDataLoaded">
  <mat-spinner></mat-spinner>
</div>
<section *ngIf="isInitialDataLoaded">
  <div class="container">
    <div class="selector-container">
      <div class="duoinput">
        <app-calc-input
          class="custom-input tipo"
          [label]="'Aliment Type'"
          [options]="filteredAliments"
          [selectedOption]="selectedItems['aliment']"
          (selectedOptionChange)="onSelect('aliment', $event)"
        >
        </app-calc-input>

        <app-calc-input
          class="custom-input material"
          [label]="'Raw Material / Product'"
          [options]="filteredProducts"
          [selectedOption]="selectedItems['product']"
          (selectedOptionChange)="onSelect('product', $event)"
        >
        </app-calc-input>
      </div>

      <div class="duoinput">
        <app-calc-input
          class="custom-input reclamo"
          [label]="'Claim'"
          [options]="filteredClaims"
          [selectedOption]="selectedItems['claim']"
          (selectedOptionChange)="onSelect('claim', $event)"
        >
        </app-calc-input>

        <app-calc-input
          class="custom-input estado"
          [label]="'State/Production line'"
          [options]="filteredStates"
          [selectedOption]="selectedItems['state']"
          (selectedOptionChange)="onSelect('state', $event)"
        >
        </app-calc-input>
      </div>
      <div class="duoinput">
        <app-calc-input
          class="custom-input variedad"
          [label]="'Variety'"
          [options]="filteredVarieties"
          [selectedOption]="selectedItems['variety']"
          [isDisabled]="filteredVarieties.length === 0"
          (selectedOptionChange)="onSelect('variety', $event)"
        >
        </app-calc-input>

        <app-calc-input
          class="custom-input proveedor"
          [label]="'Supplier'"
          [options]="filteredSuppliers"
          [selectedOption]="selectedItems['supplier']"
          (selectedOptionChange)="onSelect('supplier', $event)"
        >
        </app-calc-input>
      </div>
      <div class="duoinput">
        <app-calc-input
          class="custom-input origen"
          [label]="'Origin'"
          [options]="filteredOrigins"
          [selectedOption]="selectedItems['origin']"
          (selectedOptionChange)="onSelect('origin', $event)"
        >
        </app-calc-input>
        <app-calc-input
          class="custom-input client"
          [label]="'Client'"
          [options]="filteredClients"
          [selectedOption]="selectedItems['client']"
          (selectedOptionChange)="onSelect('client', $event)"
        >
        </app-calc-input>
      </div>
      <div class="monoinput">
        <app-calc-text-input
          [label]="'Batch'"
          [value]="batchValue"
          (valueChange)="onBatchChange($event)"
          [placeholder]="'Batch'"
        >
        </app-calc-text-input>
      </div>
    </div>
    <div class="name-result">
      <div
        class="text-container"
        *ngIf="!isEditModeEnabled"
        matTooltip="Copiar al portapapeles"
        (click)="copyCodeToClipboard()"
      >
        <h2>
          <span
            *ngIf="isTextCopied"
            class="generated-code-container copied-message"
          >
            ¡Copiado!
          </span>
          <div *ngIf="!isTextCopied" class="generated-code-container">
            <div class="generated-code">
              {{ editedCode }}
            </div>
            <div
              class="edit-icon"
              matTooltip="Inverse calculator"
              (click)="setEditMode(true)"
            >
              <div class="icon">
                <mat-icon> edit </mat-icon>
              </div>
            </div>
          </div>
        </h2>
      </div>
      <div class="text-container edit-container" *ngIf="isEditModeEnabled">
        <div class="input-container">
          <input
            type="text"
            class="custom-input"
            [value]="editedCode"
            (input)="onCodeChange($event)"
            (keyup.enter)="setEditMode(false)"
          />
        </div>
        <div
          class="confirm-icon"
          matTooltip="Close edit mode"
          (click)="setEditMode(false)"
        >
          <div class="icon">
            <mat-icon> arrow_forward </mat-icon>
          </div>
        </div>
      </div>
      <div
        class="paste-code-from-clipboard"
      >
        <button
          mat-fab
          extended
          color="secondary"
          [ngClass]="{
            hidden: !(codeOnClipboard && codeOnClipboard !== editedCode)
          }"
          (click)="onPasteFromClipboard()"
        >
          <mat-icon>content_paste</mat-icon>
          {{ codeOnClipboard }}
        </button>
      </div>
    </div>
  </div>
</section>
