import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountInfo } from '@azure/msal-browser';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class ProfileComponent implements OnInit {

  user_info = {
    name: '',
    email: '',
  }

  constructor(
    private readonly authService: AuthService,
  ) {}

  /**
   * Crea el formulario de cambio de contraseña y obtiene los datos del usuario.
   */
  ngOnInit(): void {
    console.log(this.authService.getAccount());
    const account: AccountInfo | null = this.authService.getAccount();
    this.user_info.name = account.name || '';
    this.user_info.email = account.username || '';
  }



}
