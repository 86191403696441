import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Input,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import language from 'src/assets/translations/spanish.json';

export interface ColumnDefinition {
  title: string;
  column_def: string;
}

export interface PaginateEvent {
  pageSize: number;
  pageIndex: number;
}

export const addAction = 'add';
export const editAction = 'edit';
export const deleteAction = 'delete';

const actions = [addAction, editAction, deleteAction] as const;
export type ActionsType = (typeof actions)[number];

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
})
export class DataTableComponent implements OnInit {
  // Inputs donde se indica diferentes detalles de la tabla
  //Nombres y orden de las columnas (json)
  @Input() columns: string[] = [];
  @Input() dataSource: any[] = [];
  @Input() searchable: boolean = true;
  @Input() columnNames: string[] = [];
  @Input() objectColumns: boolean[] = [];
  @Input() stickyHeader: boolean = false;
  @Input() allowEdit: boolean = true;
  @Input() allowDelete: boolean = true;
  @Input() allowAdd: boolean = true;
  @Input() paginatorStartSize: number = 5;
  @Input() dataSourceLength: number = -1;

  // Outputs de la tabla que emitirán eventos cuando sea necesario
  @Output() onEdit = new EventEmitter<any>();
  @Output() onAdd = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onPaginationChange = new EventEmitter<PaginateEvent>();

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // Lista con el id de cada columna y su título del header
  columnList: ColumnDefinition[] = [];

  // ACCIONES DE LA TABLA

  addAction = addAction;
  editAction = editAction;
  deleteAction = deleteAction;

  // Lista constante con las columnas de acción de la tabla
  actionList: string[] = [];
  actionIcons: any = {
    edit_row: editAction,
    delete_row: 'delete_forever',
  };

  // FILTROS DE LA TABLA

  showFilters = false;
  filterValues: { [name: string]: any } = {};
  columnsF: { [key: string]: any }[] = [];

  // DATOS DE LA TABLA

  tableDataSource = new MatTableDataSource<any>();

  // Lista con las columnas que se muestran de la tabla
  displayedColumns: string[] = [];

  // Lista con los filtros que se muestran de la tabla
  displayedFilters: string[] = [];

  // Columnas de la tabla ocultas
  hiddenColumns: string[] = ['id'];

  /**
   * Método donde se fija inicializa los datos de la tabla, los filtros
   * y el paginador.
   */
  ngAfterViewInit() {
    this.update();
    this.initFilters();

    this.paginator._intl.itemsPerPageLabel = 'Filas por página';
    this.paginator._intl.nextPageLabel = 'Siguiente';
    this.paginator._intl.previousPageLabel = 'Anterior';
    this.paginator._intl.firstPageLabel = 'Primera página';
    this.paginator._intl.lastPageLabel = 'Última página';
  }

  constructor() {}

  /**
   * Método donde se inicializa las columnas de la tabla, se añade las columnas
   * de acciones, se obtiene la cantidad de datos y se inicializa los filtros.
   */
  ngOnInit() {
    if (this.dataSourceLength < 0) {
      this.dataSourceLength = this.tableDataSource.data.length;
    }
    this.actionList = this.allowEdit ? ['edit_row'] : [];
    this.actionList = this.allowDelete
      ? [...this.actionList, 'delete_row']
      : this.actionList;

    this.displayedColumns = this.searchable ? ['select'] : ['select'];
    this.displayedFilters = this.searchable ? ['selectF'] : ['selectF'];

    this.columns.forEach((c) => {
      if (!this.hiddenColumns.includes(c)) {
        this.displayedColumns.push(c);
        this.columnsF.push({
          columnDef: c,
          filter: new FormControl(''),
          filteredOptions: new Observable<string[]>(),
        });
      }
    });

    this.actionList.forEach((c) => {
      this.displayedColumns.push(c);
    });

    this.getColumnNames();

    this.displayedFilters = this.displayedFilters.concat(
      this.columns.map((c) => c + 'F')
    );
  }

  /**
   * Emite un evento de creación de un dato.
   */
  protected addData(): void {
    this.onAdd.emit();
  }

  /**
   * Emite un evento de edición o borrado de un dato.
   *
   * @param row Fila sobre la que se aplica el evento
   * @param action Evento que se aplica
   */
  actionRow(row: any, action: string): void {
    if (action.includes(editAction)) {
      this.onEdit.emit(row);
    } else if (action.includes(deleteAction)) {
      this.onDelete.emit(row);
    }
  }

  /**
   * Actualiza los datos de la tabla y los renderiza.
   */
  public update(): void {
    setTimeout(() => {
      this.tableDataSource.data = this.dataSource;
      this.table.renderRows();
    }, 1);
  }

  /**
   * Emite un evento de cambio de valores en la paginación.
   *
   * @param event Nuevos valores del tamaño de página y la ṕagina actual
   */
  onPaginateChange(event: PageEvent): void {
    this.onPaginationChange.emit({
      pageSize: event.pageSize,
      pageIndex: event.pageIndex,
    });
  }

  /**
   * Muestra o oculta los filtros en función de si se están mostrando o no.
   */
  protected toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  /**
   * Obtiene todas las columnas de los datos en columnList y añade las
   * columnas de acción.
   */
  private getColumnNames(): void {
    let i = 0;
    for (let columnName of Object.keys(this.dataSource[0])) {
      this.columnList.push({
        title: this.columnNames[i++],
        column_def: columnName,
      });
    }

    this.columnList.push.apply(
      this.columnList,
      this.actionList.map((c) => {
        return {
          title: '',
          column_def: c,
        };
      })
    );
  }

  /**
   * Inicializa los filtros de cada columna y se suscribe a cambios en
   * el valor del filtro.
   */
  private initFilters(): void {
    this.columnsF.forEach((column, i, a) => {
      this.filterValues[column['columnDef']] = '';

      column['filter'].valueChanges.subscribe((filterValue: string) => {
        this.filterValues[column['columnDef']] = filterValue as string;
        // this.dataSource.filter = JSON.stringify(this.filterValues);
      });
    });
  }

  /**
   * Obtiene el texto de la clave id en el lenguaje correspondiente.
   *
   * @param id Clave del diccionario a obtener
   * @returns Texto en el lenguaje que toca de la clave id
   */
  protected getText(id: keyof typeof language): string {
    return language[id] || '';
  }

  /**
   * Comprueba si es la primera fila de datos de la tabla.
   *
   * @param row Fila actual
   * @returns Boolean especificando si es la primera fila
   */
  isFirstRow(row: any): boolean {
    return this.stickyHeader
      ? this.tableDataSource.data.indexOf(row) === 0
      : false;
  }
}
