// Import angular core
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

// Import base component
import { AppComponent } from './app.component';

// Material design modules
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// Custom modules
import { DataTableComponent } from './fragments/tables/data-table/data-table.component';
import { NotificationCenterComponent } from './fragments/notification-center/notification-center.component';
import { NotificationComponent } from './fragments/notification-center/notification/notification.component';
import { NavbarComponent } from './fragments/navbar/navbar.component';
import { SidebarComponent } from './fragments/sidebar/sidebar.component';
import { SimpleTableComponent } from './fragments/tables/simple-table/simple-table.component';
import { SimpleTableSearchBarComponent } from './fragments/tables/simple-table/simple-table-search-bar/simple-table-search-bar.component';

// Define the routes
import { AppRoutingModule } from './app-routing.module';

// Import the pages to be used in the routes
import { NotFoundComponent } from './pages/not-found/not-found.component';

// Import the interceptors
import { ApiModule, Configuration } from '@solverml/api';

// Import environment variables from assets
import env from './env.json';

import { CalcCalendarInputComponent } from './pages/calculadora-nombres/calc-calendar-input/calc-calendar-input.component';
import { AnalysisFilterComponent } from './pages/resultados/analysis-filter/analysis-filter.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ManageTableComponent } from './pages/administrar/manage-table/manage-table.component';
import { PendingDocumentsListComponent } from './pages/upload-files/pending-documents-list/pending-documents-list.component';
import { AuthGuard } from './guards/auth.guard';
import { MsalGuard, MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

@NgModule({
  declarations: [
    AppComponent,
    CalcCalendarInputComponent,
    DataTableComponent,
    NavbarComponent,
    NotFoundComponent,
    NotificationCenterComponent,
    NotificationComponent,
    SidebarComponent,
    SimpleTableComponent,
    SimpleTableSearchBarComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatExpansionModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    AnalysisFilterComponent,
    ManageTableComponent,
    PendingDocumentsListComponent,
    ApiModule.forRoot(
      // API Configuration
      () =>
        new Configuration({
          // Set the base path to the API
          basePath: window.location.origin + '/api',
        })
    ),

    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: env['AZURE_APP_CLIENT_ID'],
          authority: env['AZURE_AUTHORITY_ENDPOINT'],
          redirectUri: window.location.origin,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['user_impersonation', 'User.Read'],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [window.location.origin + '/api/', [env['AZURE_API_SCOPE']]],
        ]),
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    // Form field options dynamic subscript sizing
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
    AuthGuard,
  ],
})
export class AppModule {}
