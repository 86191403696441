<div class="search-bar">
  <!-- <div class="search-icon">
    <mat-icon>search</mat-icon>
  </div> -->
  <mat-form-field [style.width.%]="100" class="low-density-input">
    <button matPrefix mat-icon-button aria-label="Search" [disableRipple]="true">
      <mat-icon>search</mat-icon>
    </button>
    <input
      type="text"
      placeholder="Buscar..."
      matInput
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [width]="80"
      (focus)="update()"
    />
    <button *ngIf="formControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="formControl.setValue('')" [disableRipple]="true">
      <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
