import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import { lastValueFrom } from 'rxjs';

// Define the interface for the login subscriber
export interface LoginSubscriber {
  // Define the method to be called when the login is successful
  onLoginSuccess(): void;

  // Define the method to be called when logout is performed
  onLogout(): void;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static subscribers: LoginSubscriber[] = [];
  private static profilePic: string | ArrayBuffer | null | undefined;

  constructor(private authManager: MsalService) {}

  /**
   * Indica si el usuario está actualmente logueado en función de si tiene token.
   *
   * @returns Boolean indicando si está logueado
   */
  isLoggedIn(): boolean {
    return this.getAccount() !== null;
  }

  /**
   * Devuelve la información de la sesión actual.
   * @returns Información de la cuenta o null si no está logueado.
   */
  getAccount(): AccountInfo | null {
    return this.authManager.instance.getActiveAccount();
  }

  /**
   * Devuelve el listado con los roles del usuario actual.
   * @returns string[] con los roles del usuario.
   * @throws Error si no hay usuario logueado o no tiene ningún rol.
   */
  getRoles(): string[] {
    const account = this.getAccount();

    if (!account) {
      throw new Error('The user is not logged in');
    }

    const token = account?.idToken as string;
    const tokenPayload = token?.split('.')[1] || null; // Payload is the second part of the token
    const tokenData = JSON.parse(atob(tokenPayload)); // Decode the base64 string and parse it as JSON
    const userRoles = tokenData?.roles || [];

    return userRoles;
  }

  async getProfilePicture(): Promise<string | ArrayBuffer | null | undefined> {
    if (!this.isLoggedIn()) {
      return null;
    }

    // If the profile picture is not already fetched, fetch it and await the response
    if (!AuthService.profilePic) {
      await new Promise<void>(async (resolve, reject) => {
        (await this.getGraphClient())
          .api('/me/photo/$value')
          .get()
          .then((response) => {
            const reader = new FileReader();
            reader.readAsDataURL(response); // Convert to base64 for image display
            reader.onloadend = () => {
              const base64data = reader.result;
              AuthService.profilePic = base64data; // Set the image source in the component
            };
            resolve();
          })
          .catch((error) => {
            console.log('Error fetching profile picture:', error);
            AuthService.profilePic = null;
            reject();
          });
      });
    }

    return AuthService.profilePic;
  }

  /**
   * Realiza el login
   */
  async login(): Promise<void> {
    await lastValueFrom(this.authManager.initialize());
    await this.authManager.instance.clearCache();

    this.authManager
      .loginPopup({
        scopes: ['User.Read'],
      })
      .subscribe((response) => {
        this.authManager.instance.setActiveAccount(response.account);

        if (response) {
          this.notifySubscribers();
        }
      });
  }

  /**
   * Borra los datos del usuario guardados.
   */
  async logout(): Promise<void> {
    await lastValueFrom(this.authManager.logout());
    this.authManager.instance.clearCache();
    this.notifyLogout();
  }

  // Method to add a new subscriber to the login
  subscribe(subscriber: LoginSubscriber): void {
    AuthService.subscribers.push(subscriber);

    if (this.isLoggedIn()) {
      subscriber.onLoginSuccess();
    }
  }

  // Method to notify all the subscribers of the login
  private notifySubscribers(): void {
    AuthService.subscribers.forEach((s) => s.onLoginSuccess());
  }

  // Method to notify all the subscribers of the logout
  private notifyLogout(): void {
    AuthService.subscribers.forEach((s) => s.onLogout());
  }

  /**
   * Devuelve el cliente de la API de Graph. (Microsoft Graph)
   * @returns Graph client
   */
  private async getGraphClient(): Promise<Client> {
    const response = await this.authManager.instance.acquireTokenSilent({
      scopes: ['User.Read'],
    });

    const accessToken = response.accessToken;

    return Client.init({
      authProvider: (done) => {
        done(null, accessToken); // Pass the access token here
      },
    });
  }
}
