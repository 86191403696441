import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  VarietyResponseDTO,
  VarietiesService,
  AlimentResponseDTO,
} from '@solverml/api';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-variety-filter',
  templateUrl: './variety-filter.component.html',
  styleUrls: ['./variety-filter.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgIf,
  ],
})
export class VarietyFilterComponent implements AfterViewInit {
  @Input() selectedAliments: AlimentResponseDTO[] = [];

  @Output() selectionChange = new EventEmitter<VarietyResponseDTO[]>();

  formControl: FormControl = new FormControl('');
  optionList: VarietyResponseDTO[] = [];

  constructor(private varietiesService: VarietiesService) {
    this.formControl.disable();
  }

  getAllowedVarieties(): VarietyResponseDTO[] {
    const allowedVarieties: VarietyResponseDTO[] = this.optionList.filter(
      (variety) =>
        this.selectedAliments.some(
          (aliment) => variety.aliment.id === aliment.id
        )
    );

    if (allowedVarieties.length > 0) {
      this.formControl.enable();
    } else {
      this.formControl.disable();
      this.formControl.setValue(null);
    }

    return allowedVarieties;
  }

  async ngAfterViewInit() {
    this.optionList = await lastValueFrom(
      this.varietiesService.varietiesGetAllVarieties()
    );

    this.formControl.valueChanges.subscribe(() => {
      this.selectionChange.emit(this.formControl.value);
    });
  }
}
