<div
  class="notification"
  [ngClass]="{
    hasAttachment: notification.has_attachment
  }"
>
  <div class="clickable" (click)="downloadNotificationAttachment()">
    <div class="icon">
      <mat-icon
        [ngClass]="{
          'gradient-animation': isDownloading
        }"
        >{{ notification.icon }}</mat-icon
      >
    </div>
    <div class="content">
      <div class="title-and-date">
        <dir class="title">
          {{ notification.title }}
        </dir>
        <div class="date">
          {{
            lazyDateDifference || getLazyDateDiffenceFromNow(notification.date)
          }}
        </div>
      </div>
      <div class="desc">
        {{ notification.description }}
      </div>
    </div>
  </div>
  <div
    *ngIf="showDeleteButton"
    class="action-btn-icon"
    (click)="onDelete()"
    (mouseenter)="setDeleteButtonFill(true)"
    (mouseleave)="setDeleteButtonFill(false)"
  >
    <mat-icon>{{ deleteButtonIcon }}</mat-icon>
  </div>
  <div class="action-btn-icon" (click)="onToggleMarkAsRead()">
    <mat-icon>
      {{ notification.read ? "drafts" : "mail" }}
    </mat-icon>
  </div>
</div>
