import { BatchesService } from '@solverml/api';
import { Component, Output, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { lastValueFrom } from 'rxjs';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'batch-filter',
  templateUrl: './batch-filter.component.html',
  styleUrls: ['./batch-filter.component.css'],
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, MatAutocompleteModule, ReactiveFormsModule],
})
export class BatchFilter implements AfterViewInit {
  @Input() set selection(value: string) {
    this.batchValueChangeEmit(value);
  }

  @Output() batchValueChange = new EventEmitter<string>();

  formControl: FormControl = new FormControl('');

  search: string = '';
  autcomplete_options: string[] = [];
  text_autocomplete: string = '';

  constructor(private readonly batchecService: BatchesService) {}

  ngAfterViewInit() {
    this.refreshAutocompleteOptions('');
  }

  async refreshAutocompleteOptions(text: string) {
    this.text_autocomplete = text;
    this.autcomplete_options = await lastValueFrom(
      this.batchecService.batchesSearchBatch(this.text_autocomplete)
    );
  }

  async onKeyup($event: KeyboardEvent) {
    const value = ($event.target as HTMLInputElement).value || ' ';

    // if value is a string, then refresh the autocomplete options
    if (typeof value === 'string') {
      await this.refreshAutocompleteOptions(value);
    }
  }

  onBatchValueChange($event: any) {
    //const search = $event.target.value;
    //this.batchValueChangeEmit(search);
  }

  onAutocompleteSelectionChange(
    $event: MatAutocompleteSelectedEvent
  ) {
    this.batchValueChangeEmit($event.option.value);
  }

  private batchValueChangeEmit(search: string) {
    this.search = search;
    this.formControl.setValue(search);
    this.batchValueChange.emit(this.search);
  }

  formatOption(option: string) {
    return option.replace(
      this.text_autocomplete,
      `<b>${this.text_autocomplete}</b>`
    );
  }
}
