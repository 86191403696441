import { WebsocketService } from '@solverml/api';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

interface WebsocketAuthentication {
  uuid: string;
}

export interface WebsocketMessage {
  topic: string;
  body: any;
}

// Define the interface for the websocket message
export interface WebsocketSubscriber {
  /**
   * Define the method to be called when a new message is received.
   *The message (optional) is passed as an argument.
   * @param message (optional) The message received from the websocket
   */
  onWebsocketUpdate(message: WebsocketMessage | void): void;
}

@Injectable({
  providedIn: 'root',
})
export class WebsocketManagerService {
  // Store the subscribers for each topic
  static subscribers: { [key: string]: WebsocketSubscriber[] } = {};

  // Store the websocket connection
  static websocket: WebSocket | null = null;

  constructor(private websocketService: WebsocketService) {}

  /**
   * Method to subscribe a new subscriber to a topic
   * @param topic The topic to subscribe to
   * @param subscriber The subscriber to be added
   */
  subscribe(topic: string, subscriber: WebsocketSubscriber): void {
    // If the topic does not exist, create it
    if (!WebsocketManagerService.subscribers.hasOwnProperty(topic)) {
      WebsocketManagerService.subscribers[topic] = [];
    }

    // Add the subscriber to the topic
    WebsocketManagerService.subscribers[topic].push(subscriber);

    // Call the onWebsocketUpdate method of the subscriber
    // needed to update the new subscriber with the current state
    subscriber.onWebsocketUpdate();
  }

  /**
   * Initialize the websocket connection
   */
  initWebsocket(callback?: () => void): void {
    // Define the websocket origin based on the current URL
    const ws_origin = window.location.origin
      .replace('http', 'ws')
      .replace('https', 'wss');

    // Define the websocket URL
    const ws_url = `${ws_origin}/api/websocket/connect`;

    // Create a new websocket connection
    WebsocketManagerService.websocket = new WebSocket(ws_url);

    // Define the behavior when the connection is opened
    WebsocketManagerService.websocket.onopen = () => {
      console.log('Websocket waiting for authentication');
    };

    // Define the behavior when a message from the server is received
    WebsocketManagerService.websocket.onmessage = async (
      event: MessageEvent
    ) => {
      const message: WebsocketMessage | WebsocketAuthentication = JSON.parse(
        event.data
      );

      // Check if the message is an authentication request
      if (message.hasOwnProperty('uuid')) {
        const authentication = message as WebsocketAuthentication;
        await this.authenticate(authentication.uuid);
        callback?.(); // Call the callback after the authentication is done if it exists
        return;
      }

      const data = JSON.parse(JSON.parse(event.data)) as WebsocketMessage;

      // Notify all the subscribers of the topic
      this.notifySubscribers(data);
    };

    // Define the behavior when the connection is closed
    WebsocketManagerService.websocket.onclose = async () => {
      await this.attemptWebsocketReconnect();
      this.refreshAllTopics();
    };
  }

  /**
   * Destroy the websocket connection
   * */
  destroyWebsocket(): void {
    // Close the websocket connection
    WebsocketManagerService.websocket?.close();
    WebsocketManagerService.websocket = null;

    console.log('Websocket destroyed');
  }

  // Method to know if the websocket connection is connected
  isConnected(): boolean {
    return WebsocketManagerService.websocket?.readyState === WebSocket.OPEN;
  }

  // Method to attempt to reconnect to the websocket if the connection is lost
  private async attemptWebsocketReconnect(): Promise<void> {
    // Destroy the current websocket connection if it exists
    this.destroyWebsocket();

    // Wait exponentially increasing time before reconnecting until the maximum wait time is reached (10 seconds)
    let waitTime = 1000;
    const maxWaitTime = 10000;

    while (WebsocketManagerService.websocket === null) {
      console.log('Attempting to reconnect to the websocket');
      await new Promise((resolve) => setTimeout(resolve, waitTime));
      await new Promise((resolve) => {
        this.initWebsocket(() => resolve(0));
      });
      waitTime *= 2;
      waitTime = Math.min(waitTime, maxWaitTime);
    }
  }

  // Method to notify all the subscribers of a topic
  private notifySubscribers(message: WebsocketMessage): void {
    const topic = message.topic;

    if (WebsocketManagerService.subscribers.hasOwnProperty(topic)) {
      WebsocketManagerService.subscribers[topic].forEach((s) =>
        s.onWebsocketUpdate(message)
      );
    }
  }

  private refreshAllTopics(): void {
    const topics = Object.keys(WebsocketManagerService.subscribers);
    topics.forEach((topic) => {
      WebsocketManagerService.subscribers[topic].forEach((s) =>
        s.onWebsocketUpdate()
      );
    });
  }

  // Method to authenticate the websocket connection
  private async authenticate(uuid: string): Promise<void> {
    // Send the authentication message to the server
    await lastValueFrom(
      this.websocketService.websocketWebsocketAuthenticate(`"${uuid}"`)
    );

    console.log('Websocket authenticated');
  }
}
