/**
 * Full Stack FastAPI MARIADB
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Body for filtering analyses
 */
export interface AnalysisBodyFilter { 
    analysis_types?: Array<string> | null;
    plant_ids?: Array<string> | null;
    aliment_ids?: Array<string> | null;
    variety_ids?: Array<string> | null;
    product_ids?: Array<string> | null;
    claim_ids?: Array<string> | null;
    state_ids?: Array<string> | null;
    supplier_ids?: Array<string> | null;
    origin_ids?: Array<string> | null;
    client_ids?: Array<string> | null;
    start_date?: string | null;
    end_date?: string | null;
    limit?: number | null;
    offset?: number | null;
    timestamp?: string | null;
    batch?: string | null;
}

