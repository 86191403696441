<div class="custom-cell-editor-container">
  <mat-form-field class="custom-cell-editor" [appearance]="'outline'">
    <mat-select
      class="custom-cell-editor-panel"
      [value]="selectedValue"
      (selectionChange)="selectOption($event.value)"
    >
      <!-- Search Bar -->

      <div class="search-option">
        <input
          #searchInput
          matInput
          type="text"
          [(ngModel)]="searchText"
          (input)="onSearch()"
          placeholder="Search..."
          (ngAfterViewInit)="searchInput.focus()"
        />
      </div>

      <cdk-virtual-scroll-viewport itemSize="32" class="options-container">
        <mat-option
          *cdkVirtualFor="let option of visibleOptions"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-select>
  </mat-form-field>
</div>
