<section *ngIf="!isLoading">
  <h3>Queued Documents</h3>
  <p *ngIf="documents.length === 0">
    All documents have been successfully processed.
  </p>
  <mat-list class="documents-list">
    <mat-list-item *ngFor="let document of documents">
      <div class="item-container">
        <div class="item-content" (click)="downloadDocument(document)">
          <mat-icon
            matListIcon
            class="icon"
            *ngIf="document.status.toLowerCase().includes('pending')"
          >
            pending
          </mat-icon>

          <mat-icon
            matListIcon
            class="icon warning rotating"
            *ngIf="document.status.toLowerCase().includes('processing')"
          >
            autorenew
          </mat-icon>

          <mat-icon
            matListIcon
            class="icon error heartbeat"
            *ngIf="document.status.toLowerCase().includes('failed')"
          >
            error
          </mat-icon>

          <mat-icon
            matListIcon
            class="icon success"
            *ngIf="document.status.toLowerCase().includes('success')"
          >
            check_circle
          </mat-icon>

          <p matLine>{{ document.original_filename || document.filename }}</p>
        </div>
        <div class="item-actions">
          <button
            mat-icon-button
            *ngIf="document.status.toLowerCase().includes('failed')"
            (click)="retryDocument(document.id)"
          >
            <mat-icon> refresh </mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="
              document.status.toLowerCase().includes('failed') ||
              document.status.toLowerCase().includes('pending')
            "
            (click)="deleteDocument(document.id)"
          >
            <mat-icon> delete </mat-icon>
          </button>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</section>
<section *ngIf="isLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</section>
