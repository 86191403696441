import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  DateFilterComponent,
  DateRange,
} from './date-filter/date-filter.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AlimentFilterComponent } from './aliment-filter/aliment-filter.component';
import { ProductFilterComponent } from './product-filter/product-filter.component';
import { ClaimFilterComponent } from './claim-filter/claim-filter.component';
import { StateFilterComponent } from './state-filter/state-filter.component';
import { SupplierFilterComponent } from './supplier-filter/supplier-filter.component';
import { OriginFilterComponent } from './origin-filter/origin-filter.component';
import { ClientFilterComponent } from './client-filter/client-filter.component';
import { VarietyFilterComponent } from './variety-filter/variety-filter.component';
import {
  AlimentResponseDTO,
  ClaimResponseDTO,
  ClientResponseDTO,
  OriginResponseDTO,
  PlantResponseDTO,
  ProductResponseDTO,
  StateResponseDTO,
  SupplierResponseDTO,
  VarietyResponseDTO,
} from '@solverml/api';
import { AnalysisTypeFilterComponent } from './analysis-type-filter/analysis-type-filter.component';
import { PlantFilterComponent } from './plant-filter/plant-filter.component';
import { LabAnalysisService } from 'src/app/services/lab-analysis.service';
import { CommonModule } from '@angular/common';

export class FilterDTO {
  analysis: string[] = [];
  plants: PlantResponseDTO[] = [];
  clients: ClientResponseDTO[] = [];
  origins: OriginResponseDTO[] = [];
  suppliers: SupplierResponseDTO[] = [];
  varieties: VarietyResponseDTO[] = [];
  states: StateResponseDTO[] = [];
  claims: ClaimResponseDTO[] = [];
  products: ProductResponseDTO[] = [];
  aliments: AlimentResponseDTO[] = [];
  dateRange: DateRange = { start: null, end: null };
  search: string = '';
}

@Component({
  selector: 'app-analysis-filter',
  templateUrl: './analysis-filter.component.html',
  styleUrls: ['./analysis-filter.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DateFilterComponent,
    SearchBarComponent,
    DateFilterComponent,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    AlimentFilterComponent,
    ProductFilterComponent,
    ClaimFilterComponent,
    StateFilterComponent,
    SupplierFilterComponent,
    OriginFilterComponent,
    ClientFilterComponent,
    VarietyFilterComponent,
    AnalysisTypeFilterComponent,
    PlantFilterComponent,
  ],
})
export class AnalysisFilterComponent {
  @Output() filterChange = new EventEmitter<FilterDTO>();

  filter: FilterDTO = new FilterDTO();

  constructor(private labAnalysisService: LabAnalysisService) {}

  onAnalysisSelectionChange($event: string[]) {
    this.filter.analysis = $event;
  }

  onPlantSelectionChange($event: PlantResponseDTO[]) {
    this.filter.plants = $event;
  }

  onClientSelectionChange($event: ClientResponseDTO[]) {
    this.filter.clients = $event;
  }
  onOriginSelectionChange($event: OriginResponseDTO[]) {
    this.filter.origins = $event;
  }
  onSupplierSelectionChange($event: SupplierResponseDTO[]) {
    this.filter.suppliers = $event;
  }
  onVarietySelectionChange($event: VarietyResponseDTO[]) {
    this.filter.varieties = $event;
  }
  onStateSelectionChange($event: StateResponseDTO[]) {
    this.filter.states = $event;
  }
  onClaimSelectionChange($event: ClaimResponseDTO[]) {
    this.filter.claims = $event;
  }
  onProductSelectionChange($event: ProductResponseDTO[]) {
    this.filter.products = $event;
  }
  onAlimentSelectionChange($event: AlimentResponseDTO[]) {
    this.filter.aliments = $event;
  }

  onDateRangeChange($event: DateRange) {
    this.filter.dateRange = $event;
  }

  onApplyFilter() {
    this.filterChange.emit(this.filter);
  }

  onClearFilters() {
    this.filter = new FilterDTO();

    this.filterChange.emit(this.filter);
  }

  isFilterdataLoaded(): boolean {
    return (
      this.labAnalysisService.isAnalysisTypeLoaded() &&
      this.labAnalysisService.isAlimentsLoaded() &&
      this.labAnalysisService.isClaimsLoaded() &&
      this.labAnalysisService.isClientsLoaded() &&
      this.labAnalysisService.isOriginsLoaded() &&
      this.labAnalysisService.isProductsLoaded() &&
      this.labAnalysisService.isStatesLoaded() &&
      this.labAnalysisService.isPlantsLoaded() &&
      this.labAnalysisService.isSuppliersLoaded()
    );
  }
}
