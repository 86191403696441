<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>ID</mat-label>
    <input
      matInput
      [(ngModel)]="data.data.id"
      placeholder="ID"
      [disabled]="data.isUpdate || data.isDelete"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input
      matInput
      [(ngModel)]="data.data.name"
      placeholder="Name"
      [disabled]="data.isDelete"
    />
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <mat-label> Aliment type</mat-label>
    <mat-select
      [value]="data.data.aliment.id"
      [disabled]="data.isDelete"
      (valueChange)="onAlimentChange($event)"
    >
      <mat-option *ngFor="let aliment of aliments" [value]="aliment.id">
        {{aliment.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!data.isDelete">
  <button mat-button color="warn" (click)="onCancel()">Cancel</button>
  <span [matTooltip]="getButtonTooltip()">
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="data"
      [disabled]="isDisabled()"
      cdkFocusInitial
    >
      Save
    </button>
  </span>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="data.isDelete">
  <button mat-button color="warn" (click)="onCancel()">Cancel</button>
  <button
    mat-flat-button
    color="warn"
    [mat-dialog-close]="data"
    cdkFocusInitial
  >
    Delete
  </button>
</mat-dialog-actions>
