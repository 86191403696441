import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AuthGuard  {

  constructor(
    protected authService: AuthService,
    protected router: Router
  ) {}

  /**
   * Función llamada antes de acceder a la ruta actual para comprobar si
   * se puede acceder o no en función de si está logeado o no.
   * @returns Boolean especificando si se puede acceder o no a la ruta actual
   */
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
