<div>

  <h1>Datos de usuario</h1>
  <hr />

  <!-- Bloque con datos del usuario actual -->
  <div>
    <p>
      Nombre: <strong> {{ user_info.name }} </strong>
    </p>
    <p>
      Correo electrónico: <strong>{{ user_info.email }} </strong>
    </p>
  </div>

</div>
